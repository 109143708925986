import React, { memo } from 'react';
// @ts-ignore
import { withFocus } from 'react-keyboard-navigation';

export interface ButtonProps {
  id: string;
  text: string;
  position: number;
  onClick: () => void;
  styles?: { [key: string]: string | number };
  tabindex?: number;
  defaultActive?: boolean;
}

export const Button: React.FC<ButtonProps> = withFocus(
  ({ forwardedRef, ...props }: ButtonProps & { forwardedRef: React.Ref<HTMLButtonElement> }) => {
    const { text } = props;
    return (
      <button ref={forwardedRef} className="button" {...props}>
        {text}
      </button>
    );
  },
);
Button.displayName = 'Button';

export default Button;
