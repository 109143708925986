/* eslint-disable class-methods-use-this */
import Device, { DevicePropsType } from 'src/class/control-device';
import convertImageToBase64 from 'src/utils/convert-image';
import { GetNetworkType } from 'src/types/main';
import { IDeviceMetadata, ITizenMetadata } from 'src/types/device-metadata';
import Store from './store';
import { TizenPlayer } from './player';

class TizenDevice extends Device {
  store: Store;

  startSchedule;

  constructor(props: DevicePropsType) {
    super(props);
    console.log('Constructor Tizen');
    this.player = new TizenPlayer(props.deviceType, this);
    this.store = new Store(props.deviceType, this);
    this.startSchedule = props.startSchedule;
  }

  reboot(): void {
    const onSuccess = (val: string) => {
      console.log(`[rebootDevice] success : ${val}`);
    };

    const onError = (error: Error) => {
      console.error(`[rebootDevice] code :${error} error name: ${error.name} message ${error.message}`);
    };

    console.log('[rebootDevice] ');
    window.b2bapis.b2bcontrol.rebootDevice(onSuccess, onError);
  }

  reload(): void {
    window.location.reload();
  }

  updateFirmware(version: string): void {
    this.reboot();
  }

  async getNetwork(): Promise<GetNetworkType> {
    let ip = null;
    let mac = null;
    let wlanMac = null;

    try {
      ip = window.b2bapis.b2bcontrol.getIPAddress();
      mac = window.b2bapis.b2bcontrol.getMACAddress('WIRED');
      wlanMac = window.b2bapis.b2bcontrol.getMACAddress('WIRELESS');
    } catch (e) {
      console.log(
        `[getIPAddress] call syncFunction exception ${e.code}name: ${e.name} message: ${e.message}`,
      );
    }

    return {
      lan: {
        ip,
        mac,
      },
      wifi: {
        ip,
        mac: wlanMac,
      },
      vpn: {
        ip: null,
      },
    };
  }

  async printscreen(): Promise<string | null> {
    return new Promise((resolve, reject) => {
      const onSuccess = (imagePath: any) => {
        console.log('[printscreen] onSuccess imagePath:', imagePath);
        convertImageToBase64(imagePath, resolve);
      };

      const onError = (error: Error) => {
        console.error(`[printscreen] code :${error} error name: ${error?.name} message ${error?.message}`);
        resolve(null);
      };

      try {
        window.b2bapis.b2bcontrol.captureScreen(onSuccess, onError);
      } catch (e) {
        console.error('b2bcontrol.captureScreen error:', e);
        reject(new Error('Error b2bapis'));
      }
    });
  }

  getTizenModel(): Promise<ITizenMetadata> {
    return new Promise((resolve) => {
      try {
        const onSuccess = (result: any) => {
          resolve({
            model: result.model,
            buildVersion: result.buildVersion,
          });
        };

        const onError = (error: Error) => {
          console.error(`[getVersion] code :${error} error name: ${error?.name} message ${error?.message}`);
        };

        tizen.systeminfo.getPropertyValue('BUILD', onSuccess, onError);
      } catch (err) {
        console.error(
          `[getPropertyValue call syncFunction exception [${err?.code}] name: ${err?.name} message: ${err?.message}`,
        );
        resolve({
          model: '',
          buildVersion: '',
        });
      }
    });
  }

  getVersion(): Promise<IDeviceMetadata> {
    return new Promise((resolve) => {
      let firmwareVersion = '';
      let serialNumber = '';
      let osVersion = '';

      function showError(funcName: string, err: any) {
        console.error(
          `[${funcName}] call syncFunction exception [${err?.code}] name: ${err?.name} message: ${err?.message}`,
        );
      }

      try {
        osVersion = window.b2bapis.b2bcontrol.getVersion();
      } catch (e) {
        showError('getVersion', e);
      }

      try {
        firmwareVersion = window.b2bapis.b2bcontrol.getFirmwareVersion();
      } catch (e) {
        showError('getFirmwareVersion', e);
      }

      try {
        serialNumber = window.b2bapis.b2bcontrol.getSerialNumber();
      } catch (e) {
        showError('getSerialNumber', e);
      }

      this.getTizenModel().then((tizenModel) => {
        resolve({
          firmwareVersion,
          serialNumber,
          osVersion,
          ...tizenModel,
        });
      });
    });
  }
}

export default TizenDevice;
