import Device from 'src/class/control-device';

export default function changeTimezone(timezone: string, device: Device) {
  const { deviceType, timezoneDevice } = window;

  if ((deviceType === 'arm' || deviceType === 'web0s') && timezone && timezoneDevice !== timezone) {
    return device.setTimezone(timezone).then((isChanged) => {
      if (isChanged) {
        // eslint-disable-next-line no-unused-expressions
        Intl.DateTimeFormat().resolvedOptions().timeZone;
        window.timezoneDevice = timezone;
      }

      return timezone;
    });
  }

  return timezone;
}
