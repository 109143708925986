import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import orderBy from 'lodash/orderBy';
import * as Types from 'src/types/main';
import logger from 'src/utils/logger';
import { getNextItem } from 'src/utils/carousel-item';
import { DeviceContext } from 'src/context';
//
import { MediaItemComponent } from './media-item';
import { TemplateComponent } from './template';
import { DEFAULT_TIMESLOT_ITEM_DURATION, MIN_TIMESLOT_ITEM_DURATION } from './timeslot';

interface PlaylistComponentProps {
  data: Types.PlaylistType;
  style?: { [key: string]: string };
  fullscreen?: boolean;
  onPlaybackEnd?: () => void;
}

export const PlaylistComponent: React.FC<PlaylistComponentProps> = (props) => {
  const { data, style, onPlaybackEnd: onPlaylistPlaybackEnd } = props;
  useContext(DeviceContext);
  const [activeItem, setActiveItem] = useState<Types.PlaylistItemType>();
  logger('Playlist', data);
  const playlistItems = data?.items || [];
  const listMedia = useMemo(() => orderBy(playlistItems, 'sequence', 'asc'), [playlistItems]);

  const nextItemGetter = useMemo(
    () => getNextItem<Types.TimeslotItemType>(listMedia, onPlaylistPlaybackEnd),
    [listMedia],
  );

  const onPlaybackEnd = useCallback(() => {
    const nextItem = nextItemGetter();
    logger('Playlist call onPlaybackEnd', { nextItemId: nextItem?.id });
    setActiveItem(nextItem);
  }, [setActiveItem, nextItemGetter]);

  useEffect(() => {
    if (listMedia?.length === 0) {
      return () => {};
    }
    if (!activeItem) {
      setActiveItem(listMedia[0]);
      return () => {};
    }

    // !This useEffect must NOT handle video because it's rotation is handled by 'onPlaybackEnd' callback
    if (activeItem.mediaItem?.type === 'video') {
      return () => {};
    }

    const timeoutId = setTimeout(() => {
      setActiveItem(nextItemGetter());
    }, Math.max(activeItem.duration * 1000 || DEFAULT_TIMESLOT_ITEM_DURATION, MIN_TIMESLOT_ITEM_DURATION));

    return () => clearTimeout(timeoutId);
  }, [listMedia, activeItem, setActiveItem]);

  useEffect(() => {
    setActiveItem(listMedia[0]);
  }, [data]);

  return useMemo(() => {
    const { template, mediaItem } = activeItem || {};
    if (template) {
      return <TemplateComponent data={template} />;
    }
    if (mediaItem) {
      return <MediaItemComponent data={mediaItem} onPlaybackEnd={onPlaybackEnd} style={style} />;
    }
    //
    return null;
  }, [activeItem, onPlaybackEnd]);
};
PlaylistComponent.displayName = 'PlaylistComponent';

export default memo(PlaylistComponent) as typeof PlaylistComponent;
