import styled from 'styled-components';

export const Wrapper = styled.div<{
  fontColor: string;
  fontSize: number;
  fontFamily: string;
  horizontalAlignment: string;
}>`
  display: flex;
  align-items: center;

  justify-content: ${({ horizontalAlignment }) => {
    switch (horizontalAlignment) {
      case 'left':
        return 'flex-start';
      case 'right':
        return 'flex-end';
      case 'center':
      default:
        return 'center';
    }
  }};

  color: ${({ fontColor }) => fontColor};
  font-size: ${({ fontSize }) => fontSize}px;
  font-family: ${({ fontFamily }) => fontFamily};
`;
