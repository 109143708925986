/* eslint-disable class-methods-use-this */
import * as Types from 'src/types/main';
import logger from 'src/utils/logger';
import { uuid } from 'src/utils/uuid';
import { IDeviceMetadata } from 'src/types/device-metadata';
import { Store } from './store';
import Player from './player';

export type DevicePropsType = {
  deviceType: string;
  startSchedule: () => void;
  packageVersion: string;
};
export abstract class Device {
  store: Store;

  player: Player;

  startSchedule: () => void;

  public initialized?: boolean;

  private _id: string;

  static getType() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const deviceList: Types.DeviceType[] = ['arm', 'aarch64', 'tizen', 'web0s', 'chrome'];
    const deviceType: Types.DeviceType = deviceList.reduce((result, typeDevice) => {
      if (result) {
        return result;
      }

      if (userAgent.indexOf(typeDevice) !== -1) {
        return typeDevice === 'aarch64' ? 'arm' : typeDevice;
      }

      return null;
    }, null)!;
    //
    window.deviceType = deviceType;

    return deviceType;
  }

  get type() {
    return Device.getType();
  }

  get id(): string {
    return this._id;
  }

  set id(id: string | undefined | null) {
    let deviceId = id || this._id;
    if (!deviceId) {
      deviceId = uuid();
      window.localStorage.setItem('deviceId', deviceId);
    }
    this._id = deviceId;
  }

  constructor({ deviceType, startSchedule, packageVersion }: DevicePropsType) {
    this.startSchedule = startSchedule;
    const storedDeviceId = window.localStorage.getItem('deviceId');
    this.id = storedDeviceId;
    logger('constructor device', { deviceType, packageVersion });
  }

  reboot(): void {
    throw new Error('You must redefine "Device.reboot" method');
  }

  reload(): void {
    throw new Error('You must redefine "Device.reload" method');
  }

  printscreen(token?: string | null): Promise<string | null> {
    throw new Error('You must redefine "Device.printscreen" method');
  }

  setTimezone(timezone: string): Promise<boolean> {
    throw new Error('You must redefine "Device.setTimezone" method');
  }

  getVersion(): Promise<IDeviceMetadata> {
    throw new Error('You must redefine "Device.getVersion" method');
  }

  setOverscan(param: Types.SetOverscanType): void {
    throw new Error('You must redefine "Device.setOverscan" method');
  }

  getNetwork(): Promise<Types.GetNetworkType> {
    throw new Error('You must redefine "Device.getNetwork" method');
  }

  changeResolution(resolution: Types.ResolutionType): void {
    throw new Error('You must redefine "Device.changeResolution" method');
  }

  updateFirmware(version: string): void {
    throw new Error('You must redefine "Device.updateFirmware" method');
  }

  getPublicIp(): Promise<string> {
    return new Promise<string>((resolve) => {
      resolve('');
      // const xhr = new XMLHttpRequest();
      // const href = new URL(PUBLIC_SERVICE_IP);
      // xhr.open('Get', href);
      // xhr.setRequestHeader('Content-Type', 'application/json');
      // xhr.send();

      // xhr.onload = () => {
      //   if (xhr.status >= 200 && xhr.status < 300) {
      //     logger('publicIp onload', { xhr });
      //     const response = JSON.parse(xhr.response);
      //     resolve(response.ip);
      //   } else {
      //     logger('publicIp onload bad status', xhr.status);
      //     resolve('');
      //   }
      // };

      // xhr.onerror = () => {
      //   logger('publicIp onload error', xhr);
      //   resolve('');
      // };
    });
  }

  set version(ver: string) {
    throw new Error('You must redefine "Device.version" method');
  }
}

export default Device;
