import React, { FC } from 'react';
import { IWayfindingProps } from '../Iwayfinding';
import { Wrapper } from './pagination.styles';

interface IPaginationProps {
  isEnabled: boolean;
  data: IWayfindingProps['eventsProps']['pagination'];
  counter: number;
  totalCounter: number;
}

const Pagination: FC<IPaginationProps> = ({
  isEnabled,
  data: { fontFamily, fontSize = 64, fontColor = '#fff', horizontalAlignment },
  counter,
  totalCounter,
}) => {
  if (!isEnabled) {
    return null;
  }

  return (
    <Wrapper
      fontColor={fontColor}
      fontFamily={fontFamily}
      fontSize={fontSize}
      horizontalAlignment={horizontalAlignment}
    >
      Page {counter + 1} of {totalCounter}
    </Wrapper>
  );
};

export default Pagination;
