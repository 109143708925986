/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import * as Types from 'src/types/main';
import CustEventTarget from '@ungap/event-target'; // Polyfill EventTarget need for Tizen OS, installed old chromium
import logger from 'src/utils/logger';
import { Device } from './control-device';

const CustomEvent = require('custom-event');

export type FileEntryType = {
  toURL(): string;
};

export type LoadingFileStatus = 'loading' | 'downloaded' | 'error';

export type LoadingFileType = {
  status: LoadingFileStatus;
  loaded: number;
  total: number;
};

export type FileDataType = {
  id: string;
  url: string;
  path: string;
  type: 'video' | 'image';
  extension: string;
  fileType: string;
  flags: { [k: string]: boolean | string | number };
};

export type UsedMemoryType = { used: number; remaining: number };

export type getAndWriteType = { id: string; fullPath?: string; err?: string; storePath?: string };
//
export abstract class Store {
  cs: Types.TODO_ANY;

  deviceType: string;

  device: Device;

  files: Map<string, LoadingFileType> = new Map();

  private _progress: number = 0;

  prevPercent: number = 0;

  listener: EventTarget = new CustEventTarget();

  get loadedFilesSize() {
    return [...this.files.values()].reduce((t, f) => t + f.loaded, 0);
  }

  get totalSize() {
    return [...this.files.values()].reduce((t, f) => t + f.total, 0);
  }

  set progress(percent: number) {
    this._progress = percent;
  }

  get progress() {
    return this._progress;
  }

  set clear(percent: number) {
    this.files.clear();
  }

  constructor(deviceType: string, device: Device) {
    this.deviceType = deviceType;
    this.device = device;
    if (this.deviceType === 'chrome') {
      logger('constructor of chrome');
    } else if (this.deviceType === 'tizen') {
      logger('constructor of tizen');
    } else if (this.deviceType === 'arm') {
      logger('constructor of raspberry');
    } else if (this.deviceType === 'web0s') {
      logger('constructor of web0s');
    }
    this.listener.addEventListener('load-percent', (e) => {
      const total = this.totalSize;
      const loaded = this.loadedFilesSize;
      this._progress = Math.floor((loaded / total) * 100);
      // logger('Loading file, set progress', this._progress);
      if (total >= 0 && this.progress >= this.prevPercent) {
        this.prevPercent = this.progress;
        this.listener.dispatchEvent(new CustomEvent('file-load', { detail: { percent: this.progress } }));
      }
    });
  }

  checkStatusDownload(count: number) {
    logger('[FUNC-CALL] checkStatusDownload', count);
    // @ts-ignore
    if (window.idStoreInterval) {
      window.clearInterval(window.idStoreInterval);
    }
    // @ts-ignore
    window.idStoreInterval = setInterval(() => {
      logger('checkStatusDownload count:', { count });
      logger('checkStatusDownload this.files:', this.files);
      logger('checkStatusDownload this.files.size:', this.files.size);
      if (
        this.files.size >= count &&
        [...this.files.values()].every((file) => file.status === 'downloaded')
      ) {
        logger('checkStatusDownload download finish');
        this.device.startSchedule();
        // @ts-ignore
        window.clearInterval(window.idStoreInterval);
        window.idStoreInterval = null;
        this.prevPercent = 0;
        this.files.clear();
        // @ts-ignore
      }
    }, 2000);
  }

  initDownload(filesCount = 0) {
    logger('init download');
    const count = typeof filesCount === 'number' ? filesCount : parseInt(filesCount, 10);
    if (count > 0) {
      this.checkStatusDownload(count);
    }
    this.prevPercent = 0;
    this.progress = 0;
    this.files.clear();
  }

  getUsedMemory(): void {
    throw new Error('You must redefine "Store.getUsedMemory" method');
  }

  purge(): void {
    throw new Error('You must redefine "Store.purge" method');
  }

  deleteFile(path: string, url?: string) {
    throw new Error('You must redefine "Store.deleteFile" method');
  }

  getAndWrite(fileData: FileDataType): Promise<getAndWriteType> {
    throw new Error('You must redefine "Store.getAndWrite" method');
  }
}
