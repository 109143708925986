import React, { memo, useMemo } from 'react';
import time, { formatDate } from 'src/utils/time';
import * as Types from 'src/types/main';
import get from 'lodash/get';
import { Wrapper } from './event.styles';

interface IEventProps {
  data: Types.EventType;
  widgetProps: { [k: string]: string };
}

const Event: React.FC<IEventProps> = memo((props) => {
  const { data, widgetProps } = props;
  const { eventTime, eventTimeColor, eventLocationColor = '#c4c4c4', eventTitleColor = '#fff' } =
    widgetProps || {};
  const timeStart = useMemo(() => formatDate(time(data?.start).local(), 'HH:mm'), [data]);
  const timeEnd = useMemo(() => formatDate(time(data?.end).local(), 'HH:mm'), [data]);
  const logo = get(data, 'logo.url', null);

  const eventTimeComponent = useMemo(
    () => (
      <>
        {eventTime && (
          <p className="event-time">
            {timeStart}-{timeEnd}
          </p>
        )}
      </>
    ),
    [timeStart, timeEnd],
  );

  return (
    <Wrapper eventTimeColor={eventTimeColor}>
      <img style={{ visibility: logo ? 'visible' : 'hidden' }} src={data?.logo?.url} alt="Logo" />
      <div>
        <h2 style={{ color: eventTitleColor }}>{data?.title}</h2>
        <section>
          {eventTimeComponent}
          <h3 style={{ color: eventLocationColor }}>{data?.location?.name}</h3>
        </section>
      </div>
    </Wrapper>
  );
});

Event.displayName = 'Event';
export default Event;
