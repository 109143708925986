import React, { CSSProperties, FC, memo, useCallback, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import useFetch from 'src/hooks/use-fetch';
import get from 'lodash/get';
import time, { formatDate } from 'src/utils/time';
import { pascalCase } from 'src/utils/string-utils';
import * as Types from 'src/types/main';
import * as Constants from 'src/constants';

export interface FlightsWidgetProps {
  id: string;
  props?: {
    airport: string;
    direction: Types.FlightsDirectionType;
    mainColor: CSSProperties['color'];
    headerColor: CSSProperties['color'];
    visibleRows: number;
    rowHeight: number;
    separatorSize: number;
    separatorColor: CSSProperties['color'];
    paginationEnabled: boolean;
    pagination: {
      interval: number;
      margin: number;
      fontFamily: string;
      fontSize: number;
      fontColor: CSSProperties['color'];
      horizontalAlignment: string;
      verticalAlignment: string;
    };
  };
  style: CSSProperties;
}

export type FlightsTableProps = Pick<
  Required<FlightsWidgetProps>['props'],
  'headerColor' | 'mainColor' | 'separatorSize' | 'separatorColor' | 'rowHeight'
>;

const Container = styled.div<Required<FlightsWidgetProps>['props']['pagination']>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${({ verticalAlignment }) => (verticalAlignment === 'top' ? 'column' : 'column-reverse')};
  align-items: stretch;
  justify-content: center;

  & > p.pagination {
    margin: ${({ margin = 15, verticalAlignment }) =>
      verticalAlignment === 'top' ? `0 0 ${margin}px` : `${margin}px 0 0`};
    font-family: ${({ fontFamily }) => fontFamily};
    font-size: ${({ fontSize = 64 }) => fontSize}px;
    color: ${({ fontColor = '#fff' }) => fontColor};
    text-align: ${({ horizontalAlignment = 'center' }) => horizontalAlignment};
  }

  & > p.loading {
    margin: 10px 0;
    width: 100%;
    text-align: center;
    font-family: ${({ fontFamily }) => fontFamily};
    font-size: ${({ fontSize }) => fontSize}px;
    color: ${({ fontColor }) => fontColor};
  }
`;

const FlightsTable = styled.table<FlightsTableProps>`
  border-collapse: collapse;
  width: 100%;

  & th,
  & td {
    height: ${({ rowHeight }) => rowHeight}px;
    min-height: ${({ rowHeight }) => rowHeight}px;
    max-height: ${({ rowHeight }) => rowHeight}px;
    padding: 0 1.875rem;
    text-align: center;
    vertical-align: center;
  }

  & > thead > tr > th {
    color: ${({ headerColor }) => headerColor};
  }

  & > tbody > tr {
    & > td {
      color: ${({ mainColor }) => mainColor};
    }

    &:not(:last-of-type) > td {
      border-bottom-width: ${({ separatorSize }) => separatorSize}px;
      border-bottom-color: ${({ separatorColor }) => separatorColor};
      border-bottom-style: ${({ separatorSize }) => (separatorSize > 0 ? 'solid' : 'none')};
    }
  }
`;

const FlightsWidget: FC<{ data: Types.MediaItemType }> = (props) => {
  const flightProps: FlightsTableProps = get(props, 'data.props', {});
  const {
    mainColor = '#fff',
    headerColor = '#808080',
    visibleRows = 7,
    rowHeight = 72,
    separatorSize = 1,
    separatorColor = '#fff',
    paginationEnabled,
    pagination,
    direction = 'ARRIVALS',
    airport = 'DXB',
  } = (flightProps as Required<FlightsWidgetProps>['props']) || {};
  //
  const [token, setToken] = useState<string | null>(null);
  useMemo(() => {
    setToken(window.localStorage.getItem('token'));
  }, [setToken]);

  const [page, setPage] = useState(1);
  useEffect(() => {
    setPage(1);
  }, [airport, direction]);
  //
  const limit = visibleRows;
  const offset = useMemo(() => (page > 1 ? (page - 1) * visibleRows : 0), [page, visibleRows]);
  //
  const headers = useMemo(
    () => ({
      'Content-Type': 'application/json',
      Authorization: token || '',
      'Apollo-Require-Preflight': 'true',
    }),
    [token],
  );
  const body = useMemo(
    () => ({
      query: `query getFlights($filter: FlightsFilter!,$pagination: Pagination) {
        getFlights(filter:$filter,pagination:$pagination) {
          __typename
          count
          rows {
            __typename
            id
            city
            airline
            flight
            airportCode
            dateUtc
            dateLocal
            status
            gate
            actualDateUtc
            actualDateLocal
            estimatedDateLocal
            estimatedDateUtc
          }
        }
      }`,
      operationName: 'getFlights',
      variables: {
        filter: {
          airportCode: airport,
          direction,
        },
      },
    }),
    [airport, direction],
  );

  const url = new URL(`https://${process.env.API_HOST}/api`);
  const { data, refetch } = useFetch<any>(url.href, {
    headers,
    method: 'POST',
    skip: !token,
    data: body,
  });
  const totalFlights = data?.data?.getFlights?.count ?? 0;
  const pages = Math.ceil(totalFlights / visibleRows);

  const flights = useMemo(() => {
    const sliceStart = offset ? offset - 1 : offset ?? 0;
    const sliceEnd = sliceStart + limit;
    return (data?.data?.getFlights?.rows ?? []).slice(sliceStart, sliceEnd);
  }, [data, offset, limit]);

  const nextPage = useCallback(() => {
    if (page >= pages) {
      refetch();
      setPage(1);
    } else {
      setPage(page + 1);
    }
  }, [page, pages, setPage]);

  useEffect(() => {
    if (!pagination?.interval) {
      return () => null;
    }

    const paginationInterval = setInterval(() => {
      nextPage();
    }, Math.max(pagination.interval * 1000 || Constants.DEFAULT_FLIGHT_WIDGET_ROTATION_DURATION, Constants.MIN_FLIGHT_WIDGET_ROTATION_DURATION));

    return () => clearInterval(paginationInterval);
  }, [pagination?.interval, nextPage]);

  const getFlightStatusString = (flight: Types.FlightType) => {
    const { status, dateUtc, actualDateUtc, estimatedDateUtc } = flight;

    if (status === Constants.FLIGHT_STATUS.Scheduled) {
      return `On time ${formatDate(estimatedDateUtc || actualDateUtc || dateUtc, 'HH:mm')}`;
    }

    if (status === Constants.FLIGHT_STATUS.Delayed) {
      const delayTime = formatDate(estimatedDateUtc || actualDateUtc || dateUtc, 'HH:mm');
      return <span style={{ color: 'red' }}>{`Delayed ${delayTime}`}</span>;
    }

    return pascalCase(status);
  };

  return (
    <Container {...pagination}>
      {paginationEnabled && <p className="pagination">{`Page ${page} of ${pages}`}</p>}
      {flights.length === 0 && <p className="loading">Loading</p>}
      <FlightsTable
        separatorSize={separatorSize}
        separatorColor={separatorColor}
        headerColor={headerColor}
        mainColor={mainColor}
        rowHeight={rowHeight}
      >
        <thead>
          <tr>
            <th>Time</th>
            <th>Flight</th>
            <th>Destination</th>
            <th>Gate</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {flights.map((row: any) => {
            const { id, dateUtc, flight, city, airportCode, gate } = row;
            return (
              <tr key={id}>
                <td>{formatDate(dateUtc, 'HH:mm')}</td>
                <td>{flight}</td>
                <td>{city ? `${city} (${airportCode})` : airportCode}</td>
                <td>{gate}</td>
                <td>{getFlightStatusString(row)}</td>
              </tr>
            );
          })}
        </tbody>
      </FlightsTable>
    </Container>
  );
};

export default memo(FlightsWidget);
