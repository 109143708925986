import styled from 'styled-components';

export const Wrapper = styled.div<{ eventTimeColor: string }>`
  display: flex;
  align-items: center;

  & > img {
    max-height: 10rem;
    width: 10rem;
    margin-right: 1.25rem;
    object-fit: contain;

    text-align: center;
    line-height: 10rem;
    font-family: 'Lato';
    font-weight: normal;
    font-size: 2rem;
  }

  & > div > h2 {
    color: ${({ color }) => color};
    font-size: inherit;
  }

  & > div > h2,
  h3 {
    margin: 0px;
    padding: 0px;
    margin-block: 0px;
    margin-inline: 0px;
    padding-inline: 0px;
  }

  & > div > section {
    align-self: start;
    width: 100%;
    color: ${({ eventTimeColor }) => eventTimeColor};

    display: flex;
    align-items: center;

    font-size: 0.7em;

    & > h3 {
      margin-left: 0.75rem;
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
    }

    & > .event-time {
      display: flex;
      padding: 0;
      margin: 0;
    }
  }

  &:nth-of-type(2) {
    margin-left: 2rem;

    & > img {
      max-height: 8rem;
      max-width: 8rem;

      line-height: 8rem;
    }
  }
`;
