import useFetch from 'src/hooks/use-fetch';
import time from 'src/utils/time';

const useEvents = () => {
  const token = window.localStorage.getItem('token');

  const headers = {
    'Content-Type': 'application/json',
    Authorization: token || '',
    'Apollo-Require-Preflight': 'true',
  };

  const body = {
    query: `query getEvents($filter: EventFilter!) {
        getEvents(filter:$filter) {
          __typename
          count
          rows {
            __typename
            id
            title
            description
            start
            end
            locationId
            logo {
                __typename
                id
                url
                type
                props
            }
            location {
              name
            }
          }
        }
      }`,
    operationName: 'getEvents',
    variables: {
      filter: {
        startAt: { from: time().startOf('day').toISOString(), to: time().add(10, 'day').toISOString() },
      },
    },
  };

  const url = new URL(`https://${process.env.API_HOST}/api`);

  return useFetch<any>(url.href, {
    headers,
    method: 'POST',
    skip: !token,
    data: body,
  });
};

export default useEvents;
