import React, { memo, useState, useCallback, useContext } from 'react';
import styled from 'styled-components';
import RaspberryDevice from 'src/raspberry/device';
import { DeviceContext } from 'src/context';
//
const Modal = styled.div<ModalType>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: absolute;
  border: 1px solid #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: #000;
  padding: 4em 2em;
`;
//
export interface ModalComponentProps {
  open: boolean;
  onToggle: () => void;
}
type ModalType = {
  open: boolean;
};
//
export const ModalComponent: React.FC<ModalComponentProps> = memo((props) => {
  const [ssid, setSsid] = useState('');
  const [psk, setPsk] = useState('');
  const { open, onToggle } = props;
  const device = useContext(DeviceContext);
  const handleSubmit = useCallback(() => {
    if (!device) {
      return;
    }
    if (device instanceof RaspberryDevice) {
      device.wifiConfig({ ssid: ssid, password: psk });
    }
  }, [ssid, psk]);
  return (
    <Modal open={open}>
      <button className="btn-close" onClick={onToggle}>
        x
      </button>
      <div className="wrap">
        <label>SSID</label>
        <input type="text" onChange={(e) => setSsid(e.target.value)} />
      </div>
      <div className="wrap">
        <label>Password</label>
        <input type="text" onChange={(e) => setPsk(e.target.value)} />
      </div>
      <button className="form-button" onClick={handleSubmit}>
        Submit
      </button>
    </Modal>
  );
});
ModalComponent.displayName = 'Modal';

export default ModalComponent;
