import useFetch from 'src/hooks/use-fetch';
import time from 'src/utils/time';

const useWayfinding = () => {
  const token = window.localStorage.getItem('token');

  const headers = {
    'Content-Type': 'application/json',
    Authorization: token || '',
    'Apollo-Require-Preflight': 'true',
  };

  const body = {
    query: `query getDeviceLocations {
        getDeviceLocations {
          rows {
            id
            isVisible
            icon {
              __typename
              id
              url
              type
              props
            }
            destinationLocation {
              id
              name
              events(filter: { overlapDateRange: { from: "${time().toISOString()}", to: "${time()
      .add(7, 'day')
      .toISOString()}" } }) {
                id
                logo {
                  __typename
                  id
                  type
                  url
                  props
                }
                start
                end
                title
              }
            }
          }
        }
      }`,
    operationName: 'getDeviceLocations',
  };

  const url = new URL(`https://${process.env.API_HOST}/api`);

  return useFetch<any>(url.href, {
    headers,
    method: 'POST',
    skip: !token,
    data: body,
  });
};

export default useWayfinding;
