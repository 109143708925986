import styled from 'styled-components';

export const Separator = styled.hr<{ size: string; color: string }>`
  width: 80%;
  height: ${(props) => props.size}px;
  border-color: ${(props) => props.color};
  background-color: ${(props) => props.color};
`;

export const LocationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
