import React, { memo, useContext, useCallback, useEffect, useState } from 'react';
//
import logger from 'src/utils/logger';
import { AppContext, handleSchedule } from 'src/utils/schedule-sync';
import { getDefaultMediaFromLocalDB } from 'src/utils/local-db';
import Web0sDevice from 'src/webos/device';
import { DeviceContext, RawScheduleContext, ActiveTimeslotContext } from 'src/context';

import { DisplayText } from '../components/display-text';
import { MediaItemComponent } from '../components/media-item';
import { TimeslotComponent } from '../components/timeslot';

export interface AppRootProps {
  token: string;
  isStarting: boolean;
  websocketConnectionStatus: boolean;
}
//
export const AppRoot: React.FC<AppRootProps> = memo(({ token, isStarting, websocketConnectionStatus }) => {
  const rawSchedule = useContext(RawScheduleContext);
  const device = useContext(DeviceContext);
  const activeTimeslot = useContext(ActiveTimeslotContext);
  const [loadingProgress, setLoadingProgress] = useState(device?.store.progress || 0);
  const defaultMedia = getDefaultMediaFromLocalDB();
  //
  const onFileLoad = useCallback(
    (e: CustomEvent) => {
      if (e.detail && e.detail.percent) {
        const percent = e.detail.percent > 100 ? 100 : e.detail.percent;
        setLoadingProgress(percent);
      }
    },
    [setLoadingProgress],
  );

  useEffect(() => {
    if (!rawSchedule || !Object.keys(rawSchedule).length || !device) {
      return;
    }
    const context: AppContext = {
      device,
    };

    logger('Handle raw schedule data...', { rawSchedule });

    handleSchedule(context, rawSchedule).catch(console.error);
  }, [rawSchedule]);

  // Track 'file-load' progress on any device except Chrome browser
  useEffect(() => {
    if (!websocketConnectionStatus) {
      console.log('Websocket disconnected, set loading progress to 100...');
      setLoadingProgress(100);
    }
    if (!rawSchedule || !Object.keys(rawSchedule).length || !device) {
      logger('Check if we should skip file loading', {
        rawSchedule,
        device,
        deviceType: device?.type,
      });
      return () => {};
    }
    device.store.listener.addEventListener('file-load', onFileLoad);

    return () => {
      device.store.listener.removeEventListener('file-load', onFileLoad);
    };
  }, [rawSchedule, device, token, websocketConnectionStatus]);

  useEffect(() => {
    console.log('loadingProgress', { loadingProgress, isStarting });
    if (!(device instanceof Web0sDevice) && loadingProgress < 100) {
      return;
    }
    if (!isStarting) {
      return;
    }
    logger('useEffect device?.startSchedule', JSON.stringify(isStarting));
    device?.startSchedule();
  }, [loadingProgress, device, isStarting]);

  if (!(device instanceof Web0sDevice) && (!isStarting || loadingProgress < 100)) {
    return <DisplayText center>Loaded {loadingProgress}%</DisplayText>;
  }

  // Here will be timeslot-specific content
  if (activeTimeslot) {
    return <TimeslotComponent data={activeTimeslot} />;
  }

  if (defaultMedia) {
    // Render default content (if any) if no time-specific media found in the schedule
    return <MediaItemComponent data={defaultMedia} loopVideo={true} />;
  }

  return <DisplayText className="no-content">No content.</DisplayText>;
});
AppRoot.displayName = 'AppRoot';

export default AppRoot;
