import React, { memo, useContext } from 'react';
import styled from 'styled-components';
import orderBy from 'lodash/orderBy';
import * as Types from 'src/types/main';
import logger from 'src/utils/logger';
import { getStyleObjectFromString } from 'src/utils/string-utils';
import { TemplateContext, SettingsContext } from 'src/context';
import { TemplateAreaComponent } from './template-area';
//
interface TemplateComponentProps {
  data: Types.TemplateType;
}

const Wrapper = styled.div`
  & > div {
    position: absolute;
  }
`;

export const TemplateComponent: React.FC<TemplateComponentProps> = memo((props) => {
  const { data } = props;
  const { bodyStyle = {} } = data;
  const settings = useContext(SettingsContext);
  const { orientation } = settings || {};
  const defaultStyle = {
    minHeight: orientation === 90 || orientation === 270 ? '100vw' : '100vh',
    position: 'relative',
    backgroundColor: '#e5e5e5',
    fontSize: '64px',
  };
  Object.assign(defaultStyle, bodyStyle);
  if (data?.title) {
    const style = {
      fontSize: '32px',
      color: '#fff',
    };
    Object.assign(defaultStyle, style);
  }
  logger('Data template', data);
  //
  return (
    <TemplateContext.Provider value={data}>
      <Wrapper style={getStyleObjectFromString(defaultStyle)}>
        {orderBy(data.areas, 'sequence', 'asc').map((area) => (
          <TemplateAreaComponent key={area.id} data={area} />
        ))}
      </Wrapper>
    </TemplateContext.Provider>
  );
});
TemplateComponent.displayName = 'TemplateComponent';

export default TemplateComponent;
