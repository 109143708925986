import React, { memo, useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import useFetch from 'src/hooks/use-fetch';
import * as Types from 'src/types/main';
import { formatPropForReact } from 'src/utils/string-utils';
import logger from 'src/utils/logger';
import time from 'src/utils/time';
import getWeatherIcon, { IconType } from './icons/index';

interface WeatherProps {
  item: Types.MediaItemType;
}
type tempType = {
  day: number;
  eve: number;
  max: number;
  min: number;
  morn: number;
  night: number;
};
type weatherItemType = {
  description: string;
  icon: string;
  id: number;
  main: string;
};
type dailyItemType = {
  dt: number;
  temp: tempType;
  weather: weatherItemType[];
};
interface dataType {
  data: {
    getWeather: { daily: dailyItemType[] };
  };
}
//
const WeatherContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  height: inherit;
  width: inherit;

  > h4 {
    color: #fefefe;
    font-size: 2.4rem;
    text-align: center;
    max-width: 90%;
    width: 50rem;
  }
`;
const WeatherItemContainer = styled.div`
  text-align: center;
`;
const WeatherDateContainer = styled.div``;
const WeatherTemperatureContainer = styled.div``;
//
const Weather: React.FC<WeatherProps> = memo(({ item }) => {
  const { props } = item;
  logger('weather props', props);
  const days = useMemo(() => get(props, 'api.days', 7), [props.api.days]);
  const icons = useMemo(() => get(props, 'icons', { size: 108, set: 'OPEN_WEATHER' }), [props.icons]);
  const location = useMemo(() => get(props, 'location', { lat: 59.931, lng: 30.36 }), [props.location]);
  const dateLabel = useMemo(() => get(props, 'dateLabel', 'DD MMM'), [props.dateLabel]);
  const scale = useMemo(() => get(props, 'temperature', 'C'), [props.temperature]);
  const [token, setToken] = useState<string | null>(null);

  useMemo(() => {
    setToken(window.localStorage.getItem('token'));
  }, [setToken]);

  const iconLink = useMemo(() => (icon: IconType) => getWeatherIcon(icons.set, icon), [icons?.set]);
  const iconStyle = useMemo(() => formatPropForReact({ width: icons?.size, height: icons?.size }), [
    icons?.size,
  ]);

  const headers = useMemo(
    () => ({
      'Content-Type': 'application/json',
      Authorization: token || '',
      'Apollo-Require-Preflight': 'true',
    }),
    [token],
  );

  const body = useMemo(
    () => ({
      query: `query getWeather($location: Location!, $days: Int) {
        getWeather(location: $location, days: $days) {
          daily
        } 
      }`,
      operationName: 'getWeather',
      variables: { location: { lat: location.lat, lng: location.lng }, days },
    }),
    [location, days],
  );

  const url = new URL(`https://${process.env.API_HOST}/api`);
  const { data } = useFetch<dataType>(url.href, {
    headers,
    method: 'POST',
    skip: !token,
    data: body,
  });
  logger('weather data', data);
  const formatDate = useCallback((dayDt) => time(dayDt * 1000).format(dateLabel), [dateLabel]);
  const convertTemperature = useCallback((temp) => (scale === 'C' ? temp : (temp * 9) / 5 + 32), [scale]);
  return (
    <WeatherContainer>
      {data?.data?.getWeather?.daily &&
        data?.data?.getWeather?.daily.map((day) => (
          <WeatherItemContainer key={day.dt}>
            <WeatherDateContainer>{formatDate(day.dt)}</WeatherDateContainer>
            <img style={iconStyle} alt="Weather" src={iconLink(day.weather[0].icon as IconType)} />
            <WeatherTemperatureContainer>
              {`${Math.floor(convertTemperature(day.temp.day))} `}
              <span>&deg;</span>
              {`${scale}`}
            </WeatherTemperatureContainer>
          </WeatherItemContainer>
        ))}
    </WeatherContainer>
  );
});
Weather.displayName = 'Weather';
export default Weather;
