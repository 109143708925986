import React, { memo, useContext } from 'react';
import * as Types from 'src/types/main';
import logger from 'src/utils/logger';
import assign from 'lodash/assign';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { getStyleObjectFromString } from 'src/utils/string-utils';
//
import { SettingsContext } from 'src/context';
import { PlaylistComponent } from './playlist';
import { MediaItemComponent } from './media-item';
import { Text } from './text';

interface TemplateAreaComponentProps {
  data: Types.TemplateAreaType;
}

const blacklistStyle = ['backgroundEnabled', 'backgroundOpacity'];

const getRatio = (orientation: number = 0) => {
  if (orientation === 90 || orientation === 270) {
    const [rw, rh] = window.ratio.split(':');
    return `${rh}:${rw}`;
  }
  return window.ratio;
};

export const TemplateAreaComponent: React.FC<TemplateAreaComponentProps> = memo((props) => {
  const { data } = props;
  const settings = useContext(SettingsContext);
  const { orientation } = settings || {};
  logger('Data TemplateArea', data);
  const { item, playlist, style } = data;
  const styleArea = style[getRatio(orientation)] || style['16:9'];
  // logger('template styleArea', styleArea);
  const filteredWrapStyle = omit(styleArea, blacklistStyle);
  const pickedStyle = pick(styleArea, blacklistStyle);
  const styleObject = getStyleObjectFromString(filteredWrapStyle);
  const assignedStyles = assign({}, pickedStyle, styleObject);
  //
  if (playlist) {
    return <PlaylistComponent data={playlist} style={assignedStyles} />;
  }
  if (item) {
    return <MediaItemComponent data={item} style={assignedStyles} loopVideo />;
  }
  return <Text data={data} style={assignedStyles} />;
});
TemplateAreaComponent.displayName = 'TemplateAreaComponent';

export default TemplateAreaComponent;
