/* eslint-disable func-names, prefer-promise-reject-errors, class-methods-use-this */
import Player, { PlayerEventType, VideoParamType } from 'src/class/player';
import isEmpty from 'lodash/isEmpty';
import logger from 'src/utils/logger';
import Device from './device';

export class RaspberryPlayer extends Player {
  device: Device;

  constructor(params: string, device: Device) {
    super(params);
    this.device = device;
  }

  calculateCoordinates(param: {
    orientation: VideoParamType['orientation'];
    style: VideoParamType['style'];
  }) {
    const { orientation, style } = param;
    const { top, left, height, width } = style;
    let x1 = 0;
    let y1 = 0;
    let x2 = 0;
    let y2 = 0;
    const styleX1 = left ? parseInt(left, 10) : 0;
    const styleY1 = top ? parseInt(top, 10) : 0;
    const styleWidth = parseInt(width, 10);
    const styleHeight = parseInt(height, 10);
    const marginBottom = window.screen.height - (styleY1 + styleHeight);
    const marginRight = window.screen.width - (styleX1 + styleWidth);
    switch (orientation) {
      case 90:
        x1 = window.screen.width - (styleY1 + styleHeight);
        y1 = styleX1;
        x2 = window.screen.width - styleY1;
        y2 = styleX1 + styleWidth;
        break;
      case 180:
        x1 = marginRight === 0 ? 0 : marginRight;
        y1 = marginBottom === 0 ? 0 : marginBottom;
        x2 = x1 + styleWidth;
        y2 = y1 + styleHeight;
        break;
      case 270:
        x1 = styleY1;
        y1 = styleX1;
        x2 = styleY1 + styleHeight;
        y2 = styleX1 + styleWidth;
        break;
      default:
        x1 = styleX1;
        y1 = styleY1;
        x2 = x1 + styleWidth;
        y2 = y1 + styleHeight;
        break;
    }
    logger('calculateCoordinates', {
      x1,
      y1,
      x2,
      y2,
      styleX1,
      styleY1,
      styleWidth,
      styleHeight,
      orientation,
    });
    return { win: `${x1} ${y1} ${x2} ${y2}` };
  }

  async playVideo(videoParam: VideoParamType) {
    const { url, orientation = 0, args = {}, style = {}, type } = videoParam;
    let status = null;

    if (!isEmpty(style)) {
      const coordinates = this.calculateCoordinates({ orientation, style });
      logger('omxplayer coordinates', { coordinates });
      Object.assign(args, coordinates);
    }

    if (type?.toLowerCase() === 'multicast') {
      this.device.playVideo({ url, orientation, args });
      return status;
    }

    status = (await this.device.playVideo({ url, orientation, args })) as PlayerEventType;
    logger('[Raspberry] playVideo status', { status });
    return status;
  }

  async stopVideo() {
    logger('stop video');
    const data = '';
    await this.device.sendData('stop', data).then((res) => {
      return res;
    });
  }
}
export default RaspberryPlayer;
