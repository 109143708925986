import React, { useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import time from 'src/utils/time';
import parseInt from 'lodash/parseInt';

const ClockContainer = styled.svg`
  position: absolute;
  left: 0;
  top: 0;
  background: transparent;
  margin: 0;
  padding: 0;
  width: ${({ clockSize }) => clockSize}px;
  height: ${({ clockSize }) => clockSize}px;
  display: flex;
  justify-content: center;
  align-items: center;

  .clock-shape {
    fill: transparent;
    stroke-width: ${({ clockSize }) => 0.01 * clockSize}px;
    stroke: ${({ color }) => color};
    opacity: 0.5;

    stroke-dasharray: ${({ perimeter }) => perimeter};
    stroke-dashoffset: 0;
  }

  .main-circle {
    fill: transparent;
    stroke-width: ${({ clockSize }) => 0.02 * clockSize}px;
    stroke: ${({ color }) => color};
    transition: stroke-dashoffset 1s;
    -webkit-animation-play-state: running;
    opacity: 0.95;

    stroke-dasharray: ${({ perimeter }) => perimeter};
    stroke-dashoffset: ${({ perimeter, mm, ss }) => -(perimeter * mm) - (perimeter / 60) * ss};

    transform: translateY(${({ clockSize }) => clockSize}px) rotate(-90deg);
  }

  .clock-minutes {
    fill: transparent;
    stroke: ${({ color }) => color};
    stroke-width: ${({ clockSize }) => 0.02 * clockSize}px;
    transition: stroke-dashoffset 1s;
    -webkit-animation-play-state: running;
    opacity: 0.75;

    transform: translateX(${({ clockSize }) => clockSize / 2}px)
      translateY(${({ clockSize }) => clockSize / 2}px) rotate(${({ mm }) => 180 + (360 / 60) * mm}deg);
  }

  .clock-hours {
    fill: transparent;
    stroke: ${({ color }) => color};
    stroke-width: ${({ clockSize }) => 0.02 * clockSize}px;
    transition: stroke-dashoffset 1s;
    -webkit-animation-play-state: running;
    opacity: 0.95;

    transform: translateX(${({ clockSize }) => clockSize / 2}px)
      translateY(${({ clockSize }) => clockSize / 2}px)
      rotate(${({ hh, mm }) => 180 + (360 / 12) * (hh + mm / 60)}deg);
  }
`;

const SlimClock = ({ item, style }) => {
  const [date, setDate] = React.useState(time());

  const { width = 400, height = 300, color = '#fff' } = style;
  const clockSize = useMemo(() => Math.min(parseInt(width), parseInt(height)), [width, height]);
  const perimeter = useMemo(() => clockSize * 3.14, [clockSize]);
  const timezone = item?.props?.timezone;

  const tick = useCallback(() => {
    if (timezone) {
      setDate(time().tz(timezone));
    } else {
      setDate(time());
    }
  }, [timezone, setDate]);

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  }, [tick]);

  const hh = parseInt(date.format('h'));
  const mm = date.minute();
  const ss = date.second();

  return (
    <ClockContainer clockSize={clockSize} color={color} perimeter={perimeter} mm={mm} hh={hh} ss={ss}>
      <circle
        className="clock-shape"
        cx={clockSize / 2}
        cy={clockSize / 2}
        r={clockSize / 2 - 0.05 * clockSize}
      />
      <circle
        className="clock-shape main-circle"
        cx={clockSize / 2}
        cy={clockSize / 2}
        r={clockSize / 2 - 0.05 * clockSize}
      />
      <line className="clock-minutes" x1={0} y1={0} x2={0} y2={clockSize / 2 - 0.05 * clockSize} />
      <line className="clock-hours" x1={0} y1={0} x2={0} y2={clockSize / 2 - 0.2 * clockSize} />
    </ClockContainer>
  );
};

export default SlimClock;
