import React, { memo, useContext, useEffect, useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import time, { formatDate } from 'src/utils/time';
import RaspberryDevice from 'src/raspberry/device';
import { SettingsContext, ActiveTimeslotContext, DeviceContext, WebsocketContext } from 'src/context';
import logger from 'src/utils/logger';
//
const DebuggerContainer = styled.div`
  position: fixed;
  top: 1em;
  left: 1em;
  border: 1px solid red;
  z-index: 100;
  color: red;
  padding: 0.5em;
  background: rgba(0, 0, 0, 0.6);
  > ul {
    margin: 0;
    padding: 0;
  }
`;

const Li = styled.li`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

export const Debugger: React.FC = memo(() => {
  const settings = useContext(SettingsContext);
  const activeTimeslot = useContext(ActiveTimeslotContext);
  const device = useContext(DeviceContext);
  const websocketConnection = useContext(WebsocketContext);
  const [isVisible, setVisibility] = useState(false);
  const { deviceName, invertVideoRotation, orientation, scheduleUpdate } = settings || {};
  const DebugList = useMemo(() => {
    return [
      { label: 'Host', value: window.location.origin },
      { label: 'Version', value: window.packageVersion },
      { label: 'Time device', value: formatDate(time()) },
      { label: 'Timezone device', value: window.timezoneDevice },
      {
        label: 'Active timeslot id',
        value: activeTimeslot?.id,
      },
      {
        label: 'Timeslot start UTC',
        value: formatDate(activeTimeslot?.start || ''),
      },
      {
        label: 'Timeslot end UTC',
        value: formatDate(activeTimeslot?.end || ''),
      },
      { label: 'Device ID', value: window.localStorage.getItem('deviceId') },
      { label: 'Device name', value: deviceName },
      { label: 'invertVideoRotation', value: JSON.stringify(invertVideoRotation) },
      { label: 'Orientation', value: orientation },
      { label: 'Schedule last update', value: formatDate(scheduleUpdate || '') },
    ];
  }, [activeTimeslot, settings]);

  const vpn = useCallback(() => {
    if (!websocketConnection) {
      return;
    }
    if (!(device instanceof RaspberryDevice)) {
      return;
    }
    if (settings?.debug) {
      logger('call debugger connect vpn');
    }
  }, [websocketConnection?.connected, device, settings]);

  useEffect(() => {
    if (device instanceof RaspberryDevice) vpn();
    setVisibility(settings?.debug || false);
  }, [settings, device]);
  //
  return isVisible ? (
    <DebuggerContainer>
      <ul>
        {DebugList.map((item) => (
          <Li key={item.label}>
            {item.label}: {item.value}
          </Li>
        ))}
      </ul>
    </DebuggerContainer>
  ) : null;
});
Debugger.displayName = 'Debugger';

export default Debugger;
