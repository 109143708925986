/* eslint-disable func-names, prefer-promise-reject-errors, class-methods-use-this */
import Player, { PlayerEventType, VideoParamType } from 'src/class/player';
import { PlayerArgsType } from 'src/types/main';
import Device from './device';

interface IPlayerOptions {
  htmlMediaOption?: {
    useUMSMediaInfo: boolean;
  };
  mediaTransportType?: 'UDP' | 'RTP' | 'RTSP';
}

export class Web0sPlayer extends Player {
  device: Device;

  objElem: HTMLObjectElement;

  constructor(params: string, device: Device) {
    super(params);
    this.device = device;
  }

  getProtocol(targetURL: any) {
    const splitURL = targetURL.split(':');
    const protocol = splitURL[0];
    return protocol;
  }

  async playVideo(videoParam: VideoParamType): Promise<PlayerEventType> {
    const { url, type, parentElement } = videoParam;
    return new Promise((resolve, reject) => {
      try {
        if (type === 'multicast') {
          const options: IPlayerOptions = {
            htmlMediaOption: {
              useUMSMediaInfo: true,
            },
          };

          const video = document.createElement('video');
          const source = document.createElement('source');
          const protocolType = this.getProtocol(url);

          // Setting streaming protocol and source target
          if (protocolType === 'udp') {
            options.mediaTransportType = 'UDP';
          } else if (protocolType === 'rtp') {
            options.mediaTransportType = 'RTP';
          } else if (protocolType === 'rtsp') {
            options.mediaTransportType = 'RTSP';
          }

          source.setAttribute('src', url);

          // Media types supported by webOS 3.0
          source.setAttribute('type', `video/mp4;mediaOption=${encodeURI(JSON.stringify(options))}`);

          video.appendChild(source);
          parentElement?.appendChild(video);

          video.load();
          video.play();

          video.addEventListener(
            'umsmediainfo',
            function (e: any) {
              try {
                const eventCheck = JSON.parse(e.detail);

                if (eventCheck?.info?.errorCode === 0) {
                  console.log('[LG] Stremaing play with no error');
                }
                if (eventCheck?.info === 'playing') {
                  console.log('[LG] Playing started.');
                }
                if (eventCheck?.info?.udp?.errorText === 'UDP Streaming Error') {
                  console.log('[LG] UDP Streaming Error');
                  resolve('error');
                }
              } catch (err: any) {
                console.log('[LG] error play', err);
                resolve('error');
              }
            },
            false,
          );
        }
      } catch (e) {
        console.log('error play', e);
        resolve('error');
      }
    });
  }

  async stopVideo() {
    console.log('stop video web0s');
  }
}
export default Web0sPlayer;
