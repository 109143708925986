import { weekdays } from 'src/constants';
import * as Types from 'src/types/main';
import time from 'src/utils/time';
import {
  getEverydayScheduleFromLocalDB,
  getWeekdayScheduleFromLocalDB,
  getSettingsFromLocalDB,
} from 'src/utils/local-db';

const TIMESLOT_TYPE_PRIORITY_ORDER = ['EVENTS', 'DATE', 'WEEKDAY', 'DAY'];

export type GetScheduleContentParams = {
  locationId: string | null;
};

type tzDifferentType = { manipulate: string; offset: number };

function tzDifferent(): tzDifferentType | null {
  const scheduleEditTzOffset = getSettingsFromLocalDB().absTzOffset;
  const currentTzOffset = Math.abs(time().utcOffset());

  if (scheduleEditTzOffset === null || currentTzOffset === null) {
    return null;
  }
  if (scheduleEditTzOffset === currentTzOffset) {
    return null;
  }
  if (scheduleEditTzOffset > currentTzOffset) {
    return { manipulate: 'add', offset: scheduleEditTzOffset - currentTzOffset };
  }
  return { manipulate: 'subtract', offset: currentTzOffset - scheduleEditTzOffset };
}

function calculateDifferent(t: time.Dayjs, different: tzDifferentType): time.Dayjs {
  if (different.manipulate === 'add') {
    return t.add(different.offset, 'minute');
  }
  return t.subtract(different.offset, 'minute');
}

export async function getContent(params: GetScheduleContentParams): Promise<Types.TimeslotType | null> {
  const { locationId } = params;
  const todayWeekday = weekdays[time().isoWeekday()!] as keyof Types.ScheduleType;
  const weekdaySchedule = getWeekdayScheduleFromLocalDB();
  const dayTimeslot: Types.TimeslotType[] = weekdaySchedule ? weekdaySchedule[todayWeekday] || [] : [];
  const everydayTimeslot: Types.TimeslotType[] = getEverydayScheduleFromLocalDB() || [];

  const currentTime = time();

  const dayContent = ([...dayTimeslot, ...everydayTimeslot] || []).filter((timeslot) => {
    if ((timeslot.items || []).length === 0) {
      return false;
    }

    if (timeslot.isAllDay) {
      return true;
    }

    const different = tzDifferent();

    const timeStart = time(timeslot.start).local();
    const tss = different === null ? timeStart : calculateDifferent(timeStart, different);
    const timeslotStartHour = tss.hour();
    const timeslotStartMinute = tss.minute();
    const timeslotStart = currentTime.clone().hour(timeslotStartHour).minute(timeslotStartMinute).second(0);

    const timeEnd = time(timeslot.end).local();
    const tse = different === null ? timeEnd : calculateDifferent(timeEnd, different);
    const timeslotEndHour = tse.hour();
    const timeslotEndMinute = tse.minute();
    const timeslotEnd = currentTime.clone().hour(timeslotEndHour).minute(timeslotEndMinute).second(0);

    return currentTime.isBetween(timeslotStart, timeslotEnd);
  });

  if (!!dayContent && dayContent.length > 0) {
    const content = TIMESLOT_TYPE_PRIORITY_ORDER.reduce((result, periodType) => {
      if (!locationId && periodType === 'EVENTS') {
        return result;
      }
      if (result) {
        return result;
      }
      return dayContent.find((f) => f.type === periodType);
    }, null)!;
    return content;
  }

  return null;
}
