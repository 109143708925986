import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import filter from 'lodash/filter';
import { Promise as promise } from 'bluebird';
//
import {
  updateSettingsInLocalDB,
  getDefaultMediaFromLocalDB,
  updateDefaultMediaInLocalDB,
  updateEverydayScheduleInLocalDB,
  updateWeekdayScheduleInLocalDB,
  getMediaFilesFromLocalDB,
  addMediaFileToLocalDB,
  getMediaFileFromLocalDB,
  removeMediaFileFromLocalDB,
  updateEventsScheduleInLocalDB,
  getSettingsFromLocalDB,
} from 'src/utils/local-db';
//
import logger from 'src/utils/logger';
import { weekdays } from 'src/constants';
import * as Types from 'src/types/main';
import time from 'src/utils/time';
import changeTimezone from 'src/utils/change-timezone';
//
import Device from 'src/class/control-device';

const CustomEvent = require('custom-event');

export type AppContext = {
  device: Device;
};

function isScheduleChange(data: Types.DataType): boolean {
  const { rawSchedule } = getSettingsFromLocalDB();
  const isEqual =
    !!rawSchedule &&
    !!Object.keys(rawSchedule).length &&
    JSON.stringify(rawSchedule) === JSON.stringify(data);

  if (isEqual) {
    return false;
  }

  updateSettingsInLocalDB({ rawSchedule: data });
  return true;
}

export function handleSchedule(context: AppContext, data: Types.DataType): Promise<string | void> {
  if (!data) {
    return promise.resolve();
  }
  const { device } = context;
  const { timezone, tzOffset = null } = data;

  if (!isScheduleChange(data)) {
    return promise
      .resolve()
      .then(() => changeTimezone(timezone, device))
      .then(() => createDownloadList(context, data))
      .then((listTimeslots) => downloadMedia(context, listTimeslots.listDownload));
  }

  const absTzOffset = tzOffset === null ? tzOffset : Math.abs(tzOffset);

  logger('handleSchedule', { timezone, tzOffset });
  logger('dayjs().utcOffset()', time().utcOffset());
  logger('device timezone', window.timezoneDevice);

  const x = new Date();
  const currentTimeZoneOffsetInHours = x.getTimezoneOffset();
  logger('currentTimeZoneOffsetInHours', currentTimeZoneOffsetInHours);

  return promise
    .resolve()
    .then(() => changeTimezone(timezone, device))
    .then(() => createDownloadList(context, data))
    .then((listTimeslots) => {
      updateSettingsInLocalDB({ scheduleUpdate: data.updatedAt, timezone, absTzOffset });
      handleRemoveExcess(context, listTimeslots);
      saveTimeslots(context, listTimeslots);
      return listTimeslots.listDownload;
    })
    .then((listDownload) => downloadMedia(context, listDownload));
}

export function saveTimeslots(context: AppContext, listTimeslots: Types.ListTimeslotsType) {
  if (!context) {
    return;
  }
  const { everydayTimeslots, groupByWeekday, defaultMediaItem, eventsTimeslots } = listTimeslots;
  updateEverydayScheduleInLocalDB(null);

  if (everydayTimeslots.length > 0) {
    updateEverydayScheduleInLocalDB(everydayTimeslots);
  }
  updateWeekdayScheduleInLocalDB(null);

  if (!isEmpty(groupByWeekday)) {
    updateWeekdayScheduleInLocalDB(groupByWeekday);
  }
  updateEventsScheduleInLocalDB(null);

  if (eventsTimeslots.length > 0) {
    updateEventsScheduleInLocalDB(eventsTimeslots);
  }

  if (defaultMediaItem) {
    updateDefaultMediaInLocalDB(defaultMediaItem);
  } else {
    const defaultMediaInLocalDB = getDefaultMediaFromLocalDB();
    if (!defaultMediaInLocalDB) {
      return;
    }
    deleteMediaFile(context, defaultMediaInLocalDB);
    updateDefaultMediaInLocalDB(null);
  }
}

export function deleteMediaFile(
  context: AppContext,
  mediaFile: Types.MediaFileType | Types.MediaItemType,
): void {
  if (!context) {
    return;
  }
  const { device } = context;
  const { type, id } = mediaFile;
  const itemDB = getMediaFileFromLocalDB(id);

  if (!itemDB) return;

  device.store.deleteFile(itemDB.path, itemDB.url);
  removeMediaFileFromLocalDB(id);

  logger('DELETE!', { type, id, itemDB });
}

export function createDownloadList(context: AppContext, dataMedia: Types.DataType): Types.ListTimeslotsType {
  const { device } = context;
  // Sort media data by weekday
  const listDownload: Types.MediaItemType[] = [];
  const { timeslots, defaultMediaItem } = dataMedia;
  if (defaultMediaItem) {
    listDownload.push(defaultMediaItem);
  }

  const everydayTimeslots: Types.TimeslotType[] =
    filter(timeslots, (timeslot) => timeslot.type === 'DAY') || [];

  const eventsTimeslots: Types.TimeslotType[] =
    filter(timeslots, (timeslot) => timeslot.type === 'EVENTS' && timeslot.deviceId === device.id) || [];

  const weekdayTimeslots = filter(
    timeslots,
    (timeslot) => timeslot.type === 'WEEKDAY' || timeslot.type === 'DATE',
  );

  const groupByWeekday: Types.ScheduleType = groupBy(
    [...weekdayTimeslots, ...eventsTimeslots],
    (deviceSchedule) => {
      return weekdays[time(deviceSchedule.start).tz(window.timezoneDevice).isoWeekday()];
    },
  );

  function handlerListMedia(listMedia: Types.TimeslotItemType[]) {
    if (listMedia.length > 0) {
      listMedia.forEach((items) => {
        const { mediaItem, template, playlist } = items;
        if (!!mediaItem && (mediaItem.type === 'video' || mediaItem.type === 'image')) {
          listDownload.push(mediaItem);
        }

        if (typeof template !== 'undefined' && template) {
          template.areas.forEach((f) => {
            if (!!f.item && (f.item.type === 'video' || f.item.type === 'image')) {
              listDownload.push(f.item);
            }
            // Playlist in template
            if (!!f.playlist && f.playlist.items) {
              handlerListMedia(f.playlist.items);
            }
          });
        }

        if (typeof playlist !== 'undefined' && playlist) {
          const playlistListMedia = playlist.items;
          if (typeof playlistListMedia !== 'undefined') handlerListMedia(playlistListMedia);
        }
      });
    }
  }

  timeslots.forEach((timeslot: Types.TimeslotType) => {
    handlerListMedia(timeslot.items);
  });

  return { listDownload, everydayTimeslots, groupByWeekday, defaultMediaItem, eventsTimeslots };
}

export function downloadMedia(
  context: AppContext,
  listForDownload: Types.MediaItemType[],
  showDivLoading: boolean = false,
): Promise<unknown> {
  if (!context) {
    return promise.resolve();
  }
  const { device } = context;

  const uniqListForDownload = uniqBy(listForDownload, 'id');

  if (uniqListForDownload.length === 0) {
    device.store.progress = 100;
    if (typeof device?.store?.listener?.dispatchEvent === 'function') {
      try {
        device.store.listener.dispatchEvent(new CustomEvent('file-load', { detail: { percent: 100 } }));
      } catch (e) {
        console.log('[uniqListForDownload] dispatchEvent error', e);
      }
      logger('ListNewFile percent', device.store.progress);
    }

    device.startSchedule();
    return promise.resolve();
  }

  const existMediaFiles: Map<Types.MediaFileType['id'], Types.MediaFileType['status']> = new Map();
  getMediaFilesFromLocalDB().forEach((file) => existMediaFiles.set(file.id, file.status));

  const ListNewFile = uniqListForDownload.filter((file) => {
    const existFileStatus = existMediaFiles.get(file.id);
    if (!existFileStatus) {
      return true;
    }

    if (existFileStatus === 'add') {
      return true;
    }

    return false;
  });

  device.store.clear = 0;
  // Reset 'progress' to zero as we need to download new media-files from CDN
  if (ListNewFile.length === 0) {
    // Set 'progress' to 100% if we don't need to download any media-items from CDN
    if (window.deviceType === 'chrome') {
      device.store.progress = 100; //! TODO Chrome work to CustomEvent
    } else {
      device.store.progress = 0;
    }
    if (typeof device?.store?.listener?.dispatchEvent === 'function') {
      try {
        device.store.listener.dispatchEvent(new CustomEvent('file-load', { detail: { percent: 100 } }));
      } catch (e) {
        console.log('[ListNewFile] dispatchEvent error', e);
      }
      logger('ListNewFile percent', device.store.progress);
    } else {
      console.warn(
        'We needed to emit "file-load" event, but no event-emitter has been found inside "device.store"',
        { device },
      );
    }
    device.startSchedule();
    return promise.resolve();
  }
  logger('stop video schedule sync');
  device.player.stopVideo();
  device.store.initDownload(ListNewFile.length);

  return promise.each(ListNewFile, (item: Types.MediaItemType & { type: 'image' | 'video' }) => {
    const { id, type, url } = item;
    const extension = url.split('.').pop() || '';
    const path = `${type}/${id}.${extension}`;
    const fileType = `${type}/${extension}`;
    addMediaFileToLocalDB({ id, status: 'add', url: '', path: '', extension: '', type });

    const options = {
      id,
      url,
      path,
      type,
      extension,
      fileType,
      flags: { create: true },
    };
    const saveDataPromise = device.store.getAndWrite(options);
    return saveDataPromise
      .then((saveData) => {
        logger('saveData', saveData);
        const { fullPath, storePath } = saveData;
        if (typeof fullPath === 'string') {
          logger('add to DB');
          addMediaFileToLocalDB({
            id,
            type,
            path: storePath as string,
            extension,
            url: fullPath,
            status: 'downloaded',
          });
        }
      })
      .catch((err) => {
        console.error('getAndWrite error:', err);
      });
  });
}

export function handleRemoveExcess(context: AppContext, listTimeslots: Types.ListTimeslotsType) {
  if (!context) {
    return;
  }

  const removeList = new Map();
  const MediaItemId = new Set();

  const templateAreaParser = (area: Types.TemplateAreaType) => {
    if (area.item && (area.item.type === 'video' || area.item.type === 'image')) {
      MediaItemId.add(area.item.id);
    }
    // Find mediaItem in playlist template
    if (area.playlist && area.playlist.items && area.playlist.items.length > 0) {
      area.playlist.items.forEach((pItem) => {
        if (pItem.mediaItem && (pItem.mediaItem.type === 'video' || pItem.mediaItem.type === 'image')) {
          MediaItemId.add(pItem.mediaItem.id);
        }
      });
    }
  };

  const timeslotParser = (timeslot: Types.TimeslotType) => {
    const { items } = timeslot;
    function itemParser(listItems: Types.TimeslotItemType[] | Types.PlaylistItemType[]) {
      listItems.forEach((item: Types.TimeslotItemType | Types.PlaylistItemType) => {
        const { mediaItem, template } = item;
        const { playlist } = item as Types.TimeslotItemType;
        if (mediaItem) {
          MediaItemId.add(mediaItem.id);
        }
        if (template) {
          template.areas.forEach(templateAreaParser);
        }

        if (playlist) {
          if (isArray(playlist.items) && playlist.items.length > 0) {
            itemParser(playlist.items);
          }
        }
      });
    }
    itemParser(items);
  };

  const { everydayTimeslots, groupByWeekday, defaultMediaItem } = listTimeslots;

  if (isArray(everydayTimeslots)) {
    everydayTimeslots.forEach(timeslotParser);
  }

  if (isObject(groupByWeekday)) {
    Object.keys(groupByWeekday).forEach((weekday: keyof Types.ScheduleType) => {
      //
      const rows = groupByWeekday[weekday];
      (rows || []).forEach(timeslotParser);
    });
  }

  if (isObject(defaultMediaItem) && defaultMediaItem.id) {
    MediaItemId.add(defaultMediaItem.id);
  }
  //
  const mediaFilesFromLocalDB = getMediaFilesFromLocalDB();
  const mediaFileTypeParser = (MediaFile: Types.MediaFileType) => {
    if (!MediaItemId.has(MediaFile.id)) {
      removeList.set(MediaFile.id, MediaFile);
    }
  };
  logger('func: handleRemoveExcess', { MediaItemId });
  mediaFilesFromLocalDB.forEach(mediaFileTypeParser);
  // logger('removeList', removeList);
  [...removeList.values()].forEach((file) => deleteMediaFile(context, file));
}
