/* eslint-disable class-methods-use-this */
import { Store, UsedMemoryType, FileDataType, getAndWriteType } from 'src/class/store';
import { Promise as promise } from 'bluebird';
import { DownloadRequest } from 'tizen-common-web/download';
import { SystemInfoNetwork } from 'tizen-common-web/systeminfo';
import Device from './device';

const CustomEvent = require('custom-event');

const STORAGE_PATH = 'downloads';

//
class TizenStore extends Store {
  device: Device;

  prevPercent: number = 0;

  constructor(params: string, device: Device) {
    super(params, device);
    console.log('Tizen device');
    this.device = device;
    tizen.filesystem.getStorage(
      'downloads',
      (storage) => {
        console.log('storage', storage);
      },
      (e) => {
        console.log('storage error', e);
      },
    );
  }

  createStore() {
    tizen.filesystem.resolve('downloads/image', (dir) => {
      console.log('dirlist image', dir);
    });
    tizen.filesystem.resolve('downloads/video', (dir) => {
      console.log('dirlist video', dir);
    });
  }

  async getUsedMemory(): Promise<UsedMemoryType> {
    return new promise((resolve) => {
      const used = tizen.systeminfo.getTotalMemory();
      const remaining = tizen.systeminfo.getAvailableMemory();
      resolve({ used, remaining });
    });
  }

  async purge(): Promise<void> {
    return new promise((onPurgeFinished) => {
      tizen.filesystem.resolve(STORAGE_PATH, (dir) => {
        const documentsDir = dir;
        dir.listFiles((files) => {
          promise
            .each(files, async (file) => {
              if (file.isDirectory) {
                return promise.resolve();
              }
              return new promise((resolve) => {
                documentsDir.deleteFile(
                  file.fullPath,
                  function onSuccess() {
                    resolve();
                    console.log('File Deleted');
                  },
                  function onError(e) {
                    console.log('Error:', e.message, e);
                    resolve();
                  },
                );
              });
            })
            .catch(console.error)
            .finally(() => onPurgeFinished());
        });
      });
    });
  }

  async deleteFile(path: string, url: string) {
    return new promise((resolve) => {
      //
      tizen.filesystem.resolve(
        STORAGE_PATH,
        function onFindSuccess(dir) {
          dir.deleteFile(
            url,
            function onSuccess() {
              console.log('File Deleted');
              resolve();
            },
            function onError(e) {
              console.log(`Error:${e}`);
              resolve();
            },
          );
        },
        function onFindFail(e) {
          console.log(`Error${e}`);
          resolve();
        },
        'rw',
      );
    });
  }

  async getAndWrite(fileData: FileDataType): Promise<getAndWriteType> {
    const { type, id: ID, path } = fileData;
    const { url } = fileData;
    const that = this;
    return new promise((resolve, reject) => {
      let downloadRequest: DownloadRequest | null = new tizen.DownloadRequest(url, STORAGE_PATH);
      let receivedBytes: number = 0;
      let totalBytes: number = 0;
      // let downloadRequest = new tizen.DownloadRequest(url, `downloads/${type}`);
      //
      tizen.systeminfo.getPropertyValue('NETWORK', (networkInfo: SystemInfoNetwork) => {
        if (networkInfo.networkType === 'NONE') {
          console.log('Network connection is not available. Download is not possible.');
          downloadRequest = null;
        }
      });
      const event = {
        /* When the download progresses (interval is platform-dependent) */
        onprogress(id: number, receivedSize: number, totalSize: number) {
          // console.log('onprogress', { id, receivedSize, totalSize });
          receivedBytes = receivedSize;
          totalBytes = totalSize;
          that.files.set(`${id}`, { status: 'loading', loaded: receivedSize, total: totalSize });
          // @ts-ignore
          that.listener.dispatchEvent(new CustomEvent('load-percent'));
        },
        /* When the user pauses the download */
        onpaused(id: number) {
          console.log(`Paused with id: ${id}`);
        },
        /* When the user cancels the download */
        oncanceled(id: number) {
          console.log(`Canceled with id: ${id}`);
        },
        /* When the download is completed */
        oncompleted(id: number, fullPath: string) {
          that.files.set(`${id}`, { status: 'downloaded', loaded: receivedBytes, total: totalBytes });
          that.listener.dispatchEvent(new CustomEvent('load-percent'));
          resolve({ id: `${id}`, fullPath });
        },
        /* When the download fails */
        onfailed(id: number, error: Error) {
          console.log(`Failed with id: ${id}, error name: ${error.name}, error: ${error}`);
          that.files.set(`${id}`, { status: 'error', loaded: receivedBytes, total: totalBytes });
          reject('error');
        },
      };
      tizen.download.start(downloadRequest, event);
    });
    //
  }
}

export default TizenStore;
