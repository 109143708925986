import isNumber from 'lodash/isNumber';

/* eslint-disable class-methods-use-this */
class Screen {
  htmlElement: HTMLElement;

  constructor() {
    const htmlElement = document.getElementById('html');
    if (!htmlElement) {
      throw new Error(`Failed to find element with "root" or "html" id`);
    }
    this.htmlElement = htmlElement;
  }

  removeClassName(el: HTMLElement): void {
    const listClasses = el.classList;
    for (let index = 0; index < listClasses.length; index += 1) {
      el.classList.remove(listClasses[index]);
    }
  }

  rotate(degrees: number, el: HTMLElement = this.htmlElement) {
    this.removeClassName(el);
    switch (degrees) {
      case 90:
        el.classList.add('rotate90');
        break;
      case 180:
        el.classList.add('rotate180');
        break;
      case 270:
        el.classList.add('rotate270');
        break;
      default:
        break;
    }
  }

  isShowMedia(): boolean {
    const res = ['img', 'video', 'iframe'].reduce((result, tag) => {
      if (result) {
        return result;
      }
      return document.querySelector(tag);
    }, null);
    return Boolean(res);
  }

  calculateRation(w: number | string, h: number | string): string {
    const width = isNumber(w) ? w : parseInt(w, 10);
    const height = isNumber(h) ? h : parseInt(h, 10);
    function gcd(a: number, b: number): number {
      return b === 0 ? a : gcd(b, a % b);
    }
    const r = gcd(width, height);
    return `${width / r}:${height / r}`;
  }
}

export default Screen;
