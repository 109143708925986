import React, { useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import time from 'src/utils/time';
import parseInt from 'lodash/parseInt';

const ClockContainer = styled.svg`
  background: transparent;
  margin: 0;
  padding: 0;
  width: ${({ clockSize }) => clockSize}px;
  height: ${({ clockSize }) => clockSize}px;
  display: flex;
  align-items: center;
  justify-content: center;

  .clock-shape {
    fill: transparent;
    stroke-width: ${({ clockSize }) => 0.05 * clockSize}px;
    stroke: rgba(255, 255, 255, 0.1);
    stroke-dasharray: ${({ dashArray }) => dashArray};
    stroke-dashoffset: 0;
    stroke-linecap: round;
    transition: 1s;
  }

  .main-circle {
    stroke: ${({ mainColor }) => mainColor};
    stroke-dashoffset: ${({ dashArray, loadingSize }) => `${dashArray - dashArray * (loadingSize / 60)}`};
  }
`;

const Content = styled.div`
  font-family: 'DS-DIGI', sans-serif;
  color: ${({ mainColor }) => mainColor};
  position: absolute;
  top: 0;
  left: 0;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  width: ${({ clockSize }) => clockSize}px;
  height: ${({ clockSize }) => clockSize}px;
  display: flex;
  align-items: center;
  justify-content: center;

  .sec {
    animation: secAnimation 1s infinite;
  }

  @keyframes secAnimation {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

const CircleClock = ({ item, style }) => {
  const [date, setDate] = React.useState(time());

  const { width = 400, height = 300, color = '#fff' } = style;
  const clockSize = useMemo(() => Math.min(parseInt(width), parseInt(height)), [width, height]);
  const timezone = item?.props?.timezone;

  const tick = useCallback(() => {
    if (timezone) {
      setDate(time().tz(timezone));
    } else {
      setDate(time());
    }
  }, [timezone, setDate]);

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  }, [tick]);

  return (
    // <div style={style}>
    <>
      <ClockContainer clockSize={clockSize} loadingSize={date.second()} dashArray={876} mainColor={color}>
        <circle
          className="clock-shape"
          cx={clockSize / 2}
          cy={clockSize / 2}
          r={clockSize / 2 - clockSize * 0.05}
        />
        <circle
          className="clock-shape main-circle"
          cx={clockSize / 2}
          cy={clockSize / 2}
          r={clockSize / 2 - clockSize * 0.05}
        />
      </ClockContainer>
      <Content mainColor={color} clockSize={clockSize} fontSize={clockSize / 5}>
        <span className="time hour">{date.format('HH')}</span>
        <b className="sec">:</b>
        <span className="time min">{date.format('mm')}</span>
      </Content>
    </>
    // </div>
  );
};

export default CircleClock;
