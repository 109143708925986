/* eslint-disable func-names, prefer-promise-reject-errors, class-methods-use-this */
import promise from 'bluebird';
import Player, { PlayerEventType, VideoParamType } from 'src/class/player';
import logger from 'src/utils/logger';
import Device from './device';

export class ChromiumPlayer extends Player {
  device: Device;

  constructor(params: string, device: Device) {
    super(params);
    this.device = device;
  }

  async playVideo(videoParam: VideoParamType) {
    logger('playVideo');
    const res: PlayerEventType = 'ended';
    return res;
  }

  async stopVideo() {
    logger('stop video chromium');
    promise.resolve();
  }
}
export default ChromiumPlayer;
