import React, { memo, useState, useContext, useCallback, useEffect, useMemo } from 'react';
import logger from 'src/utils/logger';
import { GetNetworkType } from 'src/types/main';
import TizenDevice from 'src/tizen/device';
import RaspberryDevice from 'src/raspberry/device';
import Web0sDevice from 'src/webos/device';
import useModalState from '../hooks/use-modal-state';
import { WebsocketContext, DeviceContext } from '../context';
import DisplayText from '../components/display-text';
import Button from '../components/button';
import Modal from '../components/modal';

export interface RegistrationPageProps {
  websocketConnectionStatus: boolean;
}

export function generateDeviceCode() {
  return Math.random().toString(31).substring(2, 9).toUpperCase();
}

export const RegistrationPage: React.FC<RegistrationPageProps> = memo((props) => {
  const { websocketConnectionStatus } = props;
  const socket = useContext(WebsocketContext);
  const device = useContext(DeviceContext);
  const logoClassName = useMemo(() => {
    switch (window.hostname) {
      case 'ovds.gtkcentral.net':
        return 'logo-guestTek';
      case 'ds.hoteza.com':
        return 'logo-hoteza';
      case 'dss.fjedi.com':
        return 'logo-staging';
      case 'dsu.fjedi.com':
        return 'logo-unstable';
      default:
        return 'logo-hoteza';
    }
  }, [window.hostname]);

  const [code, setCode] = useState('');
  const [network, setNetwork] = useState<null | GetNetworkType>(null);
  const { isOpen, onToggle } = useModalState();

  const getNewCode = useCallback(() => {
    const newCode = generateDeviceCode();

    const codeDB = window.localStorage.getItem('code');

    if (codeDB) {
      setCode(codeDB);
    } else {
      window.localStorage.setItem('code', newCode);
      setCode(newCode);
    }
  }, [setCode]);
  //
  const handleNewCode = useCallback(() => {
    const newCode = generateDeviceCode();
    window.localStorage.setItem('code', newCode);

    // We pass NULL as device id to generate new one
    // device!.id = undefined;

    setCode(newCode);
  }, [device, setCode]);
  //
  const handleRefresh = useCallback(() => {
    window.location.reload();
  }, []);
  //
  const getNetwork = useCallback(() => {
    if (!device) {
      return null;
    }
    if (device instanceof RaspberryDevice || device instanceof TizenDevice || device instanceof Web0sDevice) {
      device
        .getNetwork()
        .then((result) => setNetwork(result))
        .catch(logger);
    }
    return null;
  }, [device]);

  useEffect(() => {
    getNewCode();
    getNetwork();
  }, []);

  useEffect(() => {
    if (!code) return;

    if (!socket || !websocketConnectionStatus) return;

    socket.emit('registration-code', {
      code,
      deviceId: device?.id,
      resolution: {
        height: window.screenHeight, // window.innerHeight || window.document.documentElement.clientHeight;
        width: window.screenWidth, // window.innerWidth || window.document.documentElement.clientWidth;
      },
      ratio: window.ratio,
      device: window.deviceType,
      timezone: window.timezoneDevice,
      version: window.packageVersion,
    });
  }, [code, socket?.connected, websocketConnectionStatus, device]);
  //
  return (
    <>
      <Button id="refresh" text="Refresh Page" onClick={handleRefresh} position={0} />
      <Button id="new-code" text="New Code" onClick={handleNewCode} position={1} />
      <Modal open={isOpen} onToggle={onToggle} />
      {window.deviceType === 'arm' && (
        <Button id="config-wifi" text="Config WiFi" onClick={onToggle} position={2} />
      )}
      <DisplayText center className={logoClassName}>
        {websocketConnectionStatus ? code : 'Not connected'}
      </DisplayText>
      <div className="version">{window.packageVersion}</div>
      {!websocketConnectionStatus && <div className="no-internet" />}
      {network && (
        <div className="network">
          <div>
            Ethernet mac: [{network.lan?.mac}] ip: [{network.lan?.ip}]
          </div>
          {network.wifi && (
            <div>
              WiFi mac: [{network.wifi?.mac}] ip: [{network.wifi?.ip}]
            </div>
          )}
        </div>
      )}
    </>
  );
});
RegistrationPage.displayName = 'RegistrationPage';

export default RegistrationPage;
