import React, { memo } from 'react';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import * as Types from 'src/types/main';
import logger from 'src/utils/logger';
import time from 'src/utils/time';
import MediaItem from 'src/components/media-item';
import Wayfinding from 'src/widget/wayfinding';
import useEvents from 'src/hooks/use-events';
import Event from './event';
import { Separator, LocationHeader } from './events.styles';

interface EventsProps {
  data: Types.MediaItemType;
}

export type ActiveEvent = {
  activeEvent?: Types.EventType;
  nextEvent?: Types.EventType;
};

function getActiveEvent(events: Array<Types.EventType>): ActiveEvent {
  if (!Array.isArray(events) || events.length === 0) {
    return {};
  }
  const result: ActiveEvent = {};
  const currentTime = time();

  orderBy(events, (event) => time(event.start).unix(), ['asc']).forEach((event) => {
    const timeStart = time(event.start).local();
    const timeStartHour = timeStart.hour();
    const timeStartMinute = timeStart.minute();
    const eventStart = currentTime.clone().hour(timeStartHour).minute(timeStartMinute).second(0);

    const timeEnd = time(event.end).local();
    const timeEndHour = timeEnd.hour();
    const timeEndMinute = timeEnd.minute();
    const eventEnd = currentTime.clone().hour(timeEndHour).minute(timeEndMinute).second(0);

    if (!result.activeEvent && currentTime.isBetween(eventStart, eventEnd)) result.activeEvent = event;
    if (!result.nextEvent && currentTime.isBefore(eventStart)) result.nextEvent = event;
  });

  return { ...result };
}

const Events: React.FC<EventsProps> = memo((props) => {
  const { data } = useEvents();

  const eventsList: Array<Types.EventType> = get(data, 'data.getEvents.rows', []);
  const eventsProps = get(props, 'data.props', {});

  logger('useFetch event data', data);
  logger('eventsProps', { eventsProps });

  const sortEventsList = eventsList.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
  const { activeEvent, nextEvent } = getActiveEvent(sortEventsList) || {};

  const {
    separatorColor = '#fff',
    separatorSize = '1',
    separatorEnabled = true,
    nextEvent: showNext = false,
  } = eventsProps || {};

  switch (props?.data?.type) {
    case 'location-wayfinding': {
      return <Wayfinding eventsProps={eventsProps} />;
    }
    case 'location-events': {
      return (
        <>
          {activeEvent && <Event data={activeEvent} widgetProps={eventsProps} />}
          {showNext && nextEvent && (
            <>
              {separatorEnabled && <Separator color={separatorColor} size={separatorSize} />}
              <Event data={nextEvent} widgetProps={eventsProps} />
            </>
          )}
        </>
      );
    }
    case 'location-header': {
      return <LocationHeader>{activeEvent?.location?.name}</LocationHeader>;
    }
    case 'location-event-logo': {
      return (
        <MediaItem
          data={activeEvent?.logo}
          style={{ display: 'flex', height: 'inherit', alignItems: 'center' }}
        />
      );
    }
    default:
      return <div>Events</div>;
  }
});

Events.displayName = 'Events';
export default Events;
