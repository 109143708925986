/*
    chromestore.js

    Takes an optional, initial fileSchema which it creates
    upon initialization.

    fileSchema  [{path: 'path string', callback: callback function},
                {path: 'path string', callback: callback function},
                {path: 'path string', callback: callback function}]

*/
const Promise = require('bluebird');

export var ChromeStore = function (fileSchema) {
  fileSchema = typeof fileSchema !== 'undefined' ? fileSchema : [];
  let fs = null;

  function errorHandler(DOMError, fileEntry) {
    let msg = '';
    console.log('ChromeStore.errorHander', { DOMError, fileEntry });

    switch (DOMError.name) {
      case 'QuotaExceededError':
        msg = 'QuotaExceededError';
        break;
      case 'NotFoundError':
        msg = 'NotFoundError';
        break;
      case 'SecurityError':
        msg = 'SecurityError';
        break;
      case 'InvalidModificationError':
        msg = 'InvalidModificationError';
        break;
      case 'InvalidStateError':
        msg = 'InvalidStateError';
        break;
      default:
        msg = 'Unknown Error';
        break;
    }
    console.log('Error: ' + msg);
  }

  return {
    /*
            Initialize chromestore
            Request persistent filesystem and amount of bytes
            Create initial fileSchema if there is one

            requestedBytes  [int]: requested size of storage in bytes
            callback        [function]: function to be executed when initialization is complete.
                                        passed reference to initialized chromestore.
        */
    init: async function (requestedBytes, callback) {
      //Store this in that to be be used inside nested functions
      var that = this;

      function createFileSchema(schema) {
        for (var key in schema) {
          if (schema.hasOwnProperty(key)) {
            var obj = schema[key];
            if (obj['path']) {
              that.getDir(obj['path'], { create: true }, obj['callback']);
            }
          }
        }
      }

      async function requestFS(grantedBytes) {
        return new Promise((resolve) => {
          window.webkitRequestFileSystem(
            window.PERSISTENT,
            grantedBytes,
            function (filesystem) {
              fs = filesystem;
              // console.log('fs: ', that.fs, arguments); // I see this on Chrome 27 in Ubuntu
              // console.log('Granted Bytes: ' + grantedBytes);

              createFileSchema(fileSchema);

              if (callback) {
                callback(that);
              } //Execute callback
              resolve(that);
            },
            errorHandler,
          );
        });
      }

      async function getGranted(requestedBytes) {
        return new Promise((resolve) => {
          navigator.webkitPersistentStorage.requestQuota(
            requestedBytes,
            function (grantedBytes) {
              // console.log('requestQuota: ', requestedBytes);
              requestFS(grantedBytes).then(resolve);
            },
            errorHandler,
          );
        });
      }

      return getGranted(requestedBytes);
    },

    /*
            Create/get directory or directories on filesystem.
            Recursively creates directories on passed in path.
            If directory already exists, one is not made.

            path        [string]: path of directories in which to create
            callback    [function]: function to be executed when directory has been created
        */
    getDir: function (path, flags, callback) {
      function recursiveCreate(path, callback, root) {
        path = typeof path === 'object' ? path : path.split('/');
        var rootDir = root ? root : fs.root;

        // Throw out './' or '/' and move on to prevent something like '/foo/.//bar'.
        if (path[0] == '.' || path[0] == '') {
          path = path.slice(1);
        }

        rootDir.getDirectory(
          path[0],
          flags,
          function (dirEntry) {
            // Recursively add the new subfolder (if we still have another to create).
            if (path.length - 1) {
              recursiveCreate(path.slice(1), callback, dirEntry);
            } else {
              if (callback) callback(dirEntry);
            }
          },
          errorHandler,
        );
      }

      recursiveCreate(path, callback);
    },

    /*
            Delete directory

            path        [string]: path to directory in which to delete
            callback    [function]: function to be executed when directory has been deleted
        */
    deleteDir: function (path, flags, callback) {
      var flags = flags || {};
      if (flags.recursive === undefined) flags.recursive = false;

      var rootDir = fs.root;

      rootDir.getDirectory(
        path,
        {},
        function (dirEntry) {
          if (flags.recursive) {
            dirEntry.removeRecursively(function () {
              //call callback function if specified
              if (callback) callback();
            }, errorHandler);
          } else {
            dirEntry.remove(function () {
              //call callback function if specified
              if (callback) callback();
            }, errorHandler);
          }
        },
        errorHandler,
      );
    },

    /*
            Rename directory

            path        [string]: path to directory in which to rename
            newName     [string]: new name of directory
            callback    [function]: function to be executed when directory is renamed
        */
    renameDir: function (path, newName, callback) {
      var rootDir = fs.root;
      var pathArray = path.split('/');
      var pLength = pathArray.length;
      var pathToParent = '';

      for (var i = 0; i <= pLength - 2; i++) {
        pathToParent = pathToParent + pathArray[i] + '/';
      }

      rootDir.getDirectory(
        pathToParent,
        {},
        function (parentDir) {
          pathToParent = parentDir;
        },
        errorHandler,
      );

      rootDir.getDirectory(
        path,
        {},
        function (dirEntry) {
          dirEntry.moveTo(
            pathToParent,
            newName,
            function (newDir) {
              console.log(path + ' Directory renamed.');

              //call callback function if specified
              if (callback) callback(newDir);
            },
            errorHandler,
          );
        },
        errorHandler,
      );
    },

    /*
            Create/get file
            Directory in which file is created must exist before
            creating file

            path        [string]: path to new file
            create      [boolean]: true creates the file if it doesn't exist,
            exclusive   [boolean]: true will throw an error if file already exists, false will overwrite contents
            callback    [function]: function to be executed when file is created. passed the FileEntry object
        */
    getFile: function (path, flags, callback) {
      fs.root.getFile(
        path,
        flags,
        function (fileEntry) {
          if (callback) {
            callback(fileEntry);
          }
        },
        function onError(err) {
          errorHandler(err, path);
        },
      );
    },

    /*
            Delete file

            path [string]: path to file in wich to delete
        */
    deleteFile: function (path) {
      return new Promise((resolve) => {
        fs.root.getFile(
          path,
          { create: false },
          function (fileEntry) {
            fileEntry.remove(resolve, (err) => {
              errorHandler(err, fileEntry);
              resolve();
            });
          },
          (err) => {
            errorHandler(err, path);
            resolve();
          },
        );
      });
    },

    /*
            Rename file

            path        [string]: path to file in which to rename
            newName     [string]: new name of file
            callback    [function]: function in which to execute when file is renamed.
                                    passed the FileEntry object
        */
    renameFile: function (path, newName, callback) {
      var rootDir = fs.root;
      var pathArray = path.split('/');
      var pLength = pathArray.length;
      var pathToParent = '';

      for (var i = 0; i <= pLength - 2; i++) {
        pathToParent = pathToParent + pathArray[i] + '/';
      }

      rootDir.getDirectory(
        pathToParent,
        {},
        function (parentDir) {
          pathToParent = parentDir;
        },
        errorHandler,
      );

      fs.root.getFile(
        path,
        {},
        function (fileEntry) {
          fileEntry.moveTo(
            pathToParent,
            newName,
            function () {
              console.log('File renamed');

              //call callback function if specified
              if (callback) callback(fileEntry);
            },
            errorHandler,
          );
        },
        errorHandler,
      );
    },

    /*
            Return the number of used and remaining bytes in filesystem

            callback [function]: function to be executed when used and remaining bytes have been received
                                    from filesystem.  passed the number of used and remaining bytes
        */
    usedAndRemaining: function (callback) {
      navigator.webkitPersistentStorage.queryUsageAndQuota(function (used, remaining) {
        if (callback) {
          callback(used, remaining);
        }
      });
    },

    /*
            Create new FileWriter object and returns it to the caller
        */
    createWriter: function () {
      var fw = new FileWriter(fs);
      return fw;
    },

    /*
            Write to a file
            If file does not exist, createFlag must be set to True

            path        [string]: path of file in which to write / create
            fileType    [string]: type of file (eg. video/mp4, application/text)
            data        [string]: blob to be written to the file
            createFlag  [boolean]: create new file
            callback    [function]: function to be executed when data has been written

        */
    write: function (path, fileType, data, flags, callback) {
      var fw = this.createWriter();
      fw.writeData(path, fileType, data, flags, callback);
    },

    /*
            Create new DataReceiver object and returns it to the caller
        */
    createReceiver: function () {
      var receiver = new DataReceiver();
      return receiver;
    },

    /*
            Get data from a specified url
            Returns a function with 'data' parameter

            url         [string]: URL path of the file to be downloaded
            callback    [function]: function to be executed when file has finished downloading
        */
    getData: function (id, url, callback) {
      // if (callback === 'offline') return;
      var receiver = this.createReceiver();
      receiver.getData(id, url, callback);
    },

    /*
            Get data from a URL and store it in local persistent storage
            Calls getData and write in sequence

            url         [string]: URL path of the file to be downloaded
            path        [string]: path of file in which to write / create
            fileType    [string]: type of file (eg. video/mp4, application/text)
            createFlag  [boolean]: create new file
            callback    [function]: function to be executed when file has been written
        */
    getAndWrite: function (id, url, path, fileType, flags, callback) {
      var that = this;
      this.getData(id, url, function (data) {
        // console.log('write', path, fileType, data);
        that.write(path, fileType, data, flags, callback);
      });
    },

    /*
            Delete all files and directories that already exists in local persistent storage
        */
    purge: function () {
      var dirReader = fs.root.createReader();

      return new Promise((res, rej) => {
        dirReader.readEntries(function onEntriesFound(entries) {
          new Promise.all(
            entries.map((entry) => {
              return new Promise((resolve) => {
                if (entry.isDirectory) {
                  entry.removeRecursively(resolve, (err) => {
                    errorHandler(err, entry);
                    // !TODO: instead on ignoring error, maybe we should send it to our Sentry or do something else
                    resolve(err);
                  });
                } else {
                  entry.remove(resolve, (err) => {
                    errorHandler(err, entry);
                    // !TODO: instead on ignoring error, maybe we should send it to our Sentry or do something else
                    resolve(err);
                  });
                }
              });
            }),
          ).then(() => {
            res();
          }, rej);
        });
      }).then(() => this.init());
    },

    /*
            List all files that exists in the specified path.
            Outputs an array of objects

            path        [string]: path to be listed, defaults to root when not specified
            callback    [function]: function to be executed when file has been written
        */
    ls: function (path, callback) {
      var dirReader;
      var arr = [];
      var rootDir = fs.root;
      var pathArray = path.split('/');
      var pLength = pathArray.length;
      var pathToParent = '';

      for (var i = 0; i <= pLength - 1; i++) {
        pathToParent = pathToParent + pathArray[i] + '/';
      }

      rootDir.getDirectory(
        pathToParent,
        {},
        function (parentDir) {
          pathToParent = parentDir;
          dirReader = pathToParent ? pathToParent.createReader() : fs.root.createReader();
          dirReader.readEntries(function (entries) {
            if (!entries.length) {
              console.log('Filesystem is empty.');
            }

            for (var i = 0, entry; (entry = entries[i]); ++i) {
              arr.push({
                name: entry.name,
                fileEntry: entry.filesystem,
              });
            }

            if (callback) callback(arr);
          }, errorHandler);
        },
        errorHandler,
      );
    },
  };
};

/*
    FileWriter Object
    method: writeData
*/
var FileWriter = function (filesystem) {
  var fs = filesystem;
  function errorHandler(DOMError) {
    var msg = '';
    console.log({ DOMError });

    switch (DOMError.name) {
      case 'QuotaExceededError':
        msg = 'QuotaExceededError';
        break;
      case 'NotFoundError':
        msg = 'NotFoundError';
        break;
      case 'SecurityError':
        msg = 'SecurityError';
        break;
      case 'InvalidModificationError':
        msg = 'InvalidModificationError';
        break;
      case 'InvalidStateError':
        msg = 'InvalidStateError';
        break;
      default:
        msg = 'Unknown Error';
        break;
    }
    console.log('Error: ' + msg);
  }

  return {
    /*
            Write data to a file
            If file does not exist, createFlag must be set to True
            If file already exists and createFlag is set to True, its content will be overwritten

            path        [string]: path of file in which to write / create
            fileType    [string]: type of file (eg. video/mp4, application/text)
            data        [string]: blob to be written to the file
            createFlag  [boolean]: create new file
            callback    [function]: function to be executed when data has been written
        */
    writeData: function (path, fileType, data, flags, callback) {
      fs.root.getFile(
        path,
        flags,
        function (fileEntry) {
          // Create a FileWriter object for our FileEntry (log.txt).
          fileEntry.createWriter(function (fileWriter) {
            fileWriter.onwriteend = function (e) {
              console.log('Write completed.');
              if (callback) {
                callback(fileEntry);
              }
            };
            fileWriter.onerror = function (e) {
              console.log('Write failed: ' + e.toString());
            };
            // console.log('Blob', data, fileType, path);
            var arrayBuffer = new Uint8Array(data);
            var blob = new Blob([arrayBuffer], { type: fileType });

            fileWriter.write(blob);
          }, errorHandler);
        },
        errorHandler,
      );
    },
  };
};

/*
    DataReceiver object
    Method: getData
*/
var DataReceiver = function () {
  return {
    /*
            Get data from a specified url
            Returns a function with 'data' parameter

            url         [string]: URL path of the file to be downloaded
            callback    [function]: function to be executed when file has finished downloading
        */
    getData: function (id, url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'arraybuffer';
      xhr.onloadstart = function (event) {
        window.progressDownload.set(id, { status: 'added' });
        // console.log('onloadstart', progressDownload, event);
      };
      xhr.onprogress = function (event) {
        // window.progressDownload[id] = { loaded: event.loaded, total: event.total };
        window.progressDownload.set(id, { loaded: event.loaded, total: event.total });
        // console.log('onprogress', progressDownload, event.loaded, event.total);
      };

      xhr.onload = function (e) {
        // console.log('onload e', e);
        if (this.status == 200) {
          // window.progressDownload[id] = { status: 'downloaded' };
          window.progressDownload.set(id, { status: 'downloaded' });
          // console.log('progress download', progressDownload);
          callback(this.response);
        }
        callback('error');
      };

      xhr.onerror = function () {
        //!TODO Error
        console.log('error xhr');
        callback('error');
        //
      };

      xhr.send();
    },

    getProgress() {
      //
    },
  };
};
