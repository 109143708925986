/* eslint-disable class-methods-use-this */
/* eslint-disable func-names */
import { Store, UsedMemoryType, FileDataType, getAndWriteType } from 'src/class/store';
import logger from 'src/utils/logger';
import Device from './device';

export type RaspberryFileEntryFlags = { create?: boolean };

export type LoadFileParams = {
  id: string;
  url: string;
  path: string;
  fileType: string;
  flags: RaspberryFileEntryFlags;
};

class RaspberryStore extends Store {
  // listener: EventTarget = new EventTarget();

  device: Device;

  constructor(params: string, device: Device) {
    super(params, device);
    console.log('constructor raspberry', params);

    this.device = device;
  }

  async getUsedMemory(): Promise<UsedMemoryType> {
    return new Promise((resolve, reject) => {
      this.cs.usedAndRemaining((used: number, remaining: number) => {
        resolve({ used, remaining });
      });
    });
  }

  async initDownload(filesCount: number): Promise<void> {
    logger('initDownload filesCount', { filesCount });
    const result = await this.device.sendData('init-download', filesCount);
    logger('initDownload result', { result });
  }

  async purge(): Promise<void> {
    await this.device.sendData('purge');
  }

  async deleteFile(path: string, url: string) {
    await this.device.sendData('delete-file', url);
  }

  async getAndWrite(fileData: FileDataType): Promise<getAndWriteType> {
    const saveData: getAndWriteType = await this.device.sendData('download', fileData);
    if (saveData.err) {
      return { id: saveData.id, err: saveData.err };
    }
    return { id: saveData.id, fullPath: `file://${saveData.fullPath}` };
  }
}

export default RaspberryStore;
