import React, { memo, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { WebsocketContext, SettingsContext } from 'src/context';
//
const DeviceNameContainer = styled.div`
  left: 1.5em;
  color: red;
  bottom: 1.5em;
  padding: 0.5em;
  position: fixed;
  font-size: 2rem;
  border: 1px solid red;
`;

export const DEVICE_NAME_VISIBILITY_TIMEOUT = 60000;

export const DeviceName: React.FC = memo(() => {
  const websocketConnection = useContext(WebsocketContext);
  const settings = useContext(SettingsContext);
  const [isVisible, setVisibility] = useState(false);
  const { deviceName } = settings || {};
  useEffect(() => {
    if (!websocketConnection) {
      return;
    }
    websocketConnection.on('device-show-name', async () => {
      setVisibility(true);
      setTimeout(() => setVisibility(false), DEVICE_NAME_VISIBILITY_TIMEOUT);
    });
  }, [websocketConnection]);
  //
  return isVisible ? <DeviceNameContainer>{deviceName}</DeviceNameContainer> : null;
});
DeviceName.displayName = 'DeviceName';

export default DeviceName;
