/* eslint-disable func-names, prefer-promise-reject-errors, class-methods-use-this */
import io from 'socket.io-client';
import type { Socket } from 'socket.io-client';
import Device, { DevicePropsType } from 'src/class/control-device';
import logger from 'src/utils/logger';
import { TODO_ANY, WifiConfigType, SetOverscanType, GetNetworkType, ResolutionType } from 'src/types/main';
import { IRaspberryMetadata } from 'src/types/device-metadata';
import Store from './store';
import { RaspberryPlayer } from './player';

const CustomEvent = require('custom-event');

class RaspberryDevice extends Device {
  socket: Socket;

  store: Store;

  locks: Set<string>;

  constructor(props: DevicePropsType) {
    super(props);

    this.player = new RaspberryPlayer(props.deviceType, this);
    this.store = new Store(props.deviceType, this);
    this.locks = new Set<string>();

    this.socket = io(`http://localhost:4000`);

    this.socket.on('connect', () => {
      logger('daemon api connect');
      this.setDeviceId();
    });

    this.socket.on('disconnect', () => {
      logger('daemon api disconnect');
    });

    this.socket.on('load-percent', (percent: number) => {
      this.store.listener.dispatchEvent(new CustomEvent('file-load', { detail: { percent } }));
    });

    this.socket.on('load-finish', () => {
      logger('socket on load-finish');
      props.startSchedule();
    });
  }

  async sendData<T>(emitName: string, data?: unknown): Promise<T> {
    return new Promise((resolve, reject) => {
      if (!this.socket) {
        reject('No socket connection.');
      } else {
        this.socket.emit(emitName, data, (response: TODO_ANY) => {
          if (!response) {
            logger('[Raspberry] sendData !response');
            reject('error');
          }
          if (response?.error) {
            console.error('[Raspberry] sendData return error:', response?.error);
            reject('error');
          } else {
            resolve(response);
          }
        });
      }
    });
  }

  playVideo(props: any): Promise<string> {
    return new Promise((resolve, reject) => {
      logger('device playVideo play');
      this.sendData('play', props);
    });
  }

  reboot(): void {
    logger('Reboot raspberry');
    this.sendData('reboot');
  }

  reload(): void {
    const result = this.sendData('reboot-chrome');
    logger('reload result', { result });
  }

  setOverscan(param: SetOverscanType): void {
    this.sendData('set-overscan', param).then(() => {
      this.reboot();
    });

    console.log('set-overscan:', { param });
  }

  printscreen(token: string): Promise<string | null> {
    if (this.locks.has('printscreen')) {
      return Promise.resolve(null);
    }
    this.locks.add('printscreen');

    return new Promise((resolve, reject) => {
      const payload = {
        token,
        apiUrl: process.env.SCREENSHOT_URL,
      };

      logger('func printscreen');

      try {
        this.sendData<string>('take-printscreen', payload)
          .then((result) => resolve(result))
          .finally(() => this.locks.delete('printscreen'));
      } catch (error) {
        console.error('function printscreen error', error);
        this.locks.delete('printscreen');
        reject();
      }
    });
  }

  async setTimezone(timezone: string = 'Europe/Moscow'): Promise<boolean> {
    const isChanged = await this.sendData<boolean>('timezone', timezone);
    logger('isChanged', isChanged);
    return isChanged;
  }

  async updateFirmware(version: string): Promise<void> {
    if (typeof version !== 'string') {
      return;
    }

    this.sendData('update-firmware', version)
      .then((result) => {
        logger('updateFirmware result', { result });
        this.reboot();
      })
      .catch((error) => {
        console.error('updateFirmware error', error);
      });
  }

  async connectVpn() {
    logger('connectVpn');
    const result = await this.sendData('connect-vpn');
    logger('connectVpn result', { result });
  }

  async disconnectVpn() {
    const result = await this.sendData('disconnect-vpn');
    logger('disconnectVpn result', { result });
  }

  async getVersion() {
    const version = await this.sendData<IRaspberryMetadata>('get-version');
    return version;
  }

  async getNetwork() {
    logger('getNetwork');
    const result = await this.sendData<GetNetworkType>('get-ip');
    return result;
  }

  setDeviceId() {
    const deviceId = this.id;
    this.sendData('set-device-id', deviceId);
  }

  async changeResolution(resolution: ResolutionType) {
    if (this.locks.has('changeResolution')) {
      return;
    }
    this.locks.add('changeResolution');

    logger('changeResolution');
    this.sendData<boolean>('change-resolution', resolution)
      .then(() => this.reboot())
      .catch((e) => console.error('changeResolution error', e))
      .finally(() => this.locks.delete('changeResolution'));
  }

  wifiConfig(setting: WifiConfigType) {
    // TODO if wifi network hidden in registration screen
    if (this.locks.has('wifiConfig')) {
      return;
    }
    this.locks.add('wifiConfig');

    this.sendData('wifi-config', setting)
      .then((result) => this.reboot())
      .catch((e) => console.error('wifiConfig error', e))
      .finally(() => this.locks.delete('wifiConfig'));
  }
}

export default RaspberryDevice;
