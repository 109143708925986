import axios from 'axios';
import Device from 'src/class/control-device';
import logger from 'src/utils/logger';

async function screenshot(device: Device, token: string | null) {
  let imgBase64 = null;

  try {
    imgBase64 = await device.printscreen(token);
  } catch (e) {
    console.error('Error printscreen', e);
  }

  if (!imgBase64 || imgBase64 === 'success') {
    return;
  }

  const url = process.env.SCREENSHOT_URL || '';

  const response = await axios(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token || '',
    },
    method: 'POST',
    data: {
      file: imgBase64,
    },
  }).catch((err) => {
    console.log('axios POST err', err);
  });

  logger('Fetching content from remote api...', { url, response });
}

export default screenshot;
