import React, { memo, useMemo, useState, useCallback, useEffect } from 'react';
import chunk from 'lodash/chunk';
import useInterval from 'src/hooks/use-interval';
import * as Types from 'src/types/main';
import time, { formatDate } from 'src/utils/time';
import logger from 'src/utils/logger';
import useWayfinding from 'src/hooks/use-wayfinding';
import Pagination from './pagination';
import { IWayfindingProps, CustomEventType } from './Iwayfinding';
import { Container, Item, Time, Title, Location, ImgContainer, LocationName } from './wayfinding.styles';

function filterEvents(room: Types.DeviceLocationType) {
  const currentTime = time();
  const transformRoom = room;
  const { events } = transformRoom.destinationLocation;

  const filteredEvents = events.filter((event) => {
    const startTime = time(event.start).local();
    const endTime = time(event.end).local();

    if (!currentTime.isSame(startTime, 'day')) {
      return false;
    }

    return currentTime.isBetween(startTime, endTime) || currentTime.isBefore(startTime);
  });

  transformRoom.destinationLocation.events = filteredEvents;

  return transformRoom;
}

const Wayfinding: React.FC<IWayfindingProps> = memo((props) => {
  const { eventsProps } = props;
  const {
    separatorColor = '#fff',
    separatorSize = 1,
    separatorEnabled = true,
    eventLocation = false,
    eventLocationColor = '#fff',
    eventTitleColor = '#fff',
    eventTimeColor = '#fff',
    eventTime = true,
    logo = true,
    onlyEvents = false,
    visibleRows = 5,
    eventLogo = true,
    pagination,
    paginationEnabled,
  } = eventsProps;

  const { interval = 15, verticalAlignment } = pagination || {};

  const [activeList, setActiveList] = useState<CustomEventType[] | []>([]);
  const [roomsEvents, setRoomsEvent] = useState<Types.DeviceLocationType[]>([]);
  const [counter, setCounter] = useState<number>(0);

  const { data, refetch } = useWayfinding();
  const { data: { getDeviceLocations = {} } = {} } = data || {};
  const { rows = [] }: { rows: Types.DeviceLocationType[] } = getDeviceLocations || {};

  const roomsHasEvents = useMemo(() => rows.filter((row) => row.destinationLocation.events.length), [rows]);

  const filterRooms = useCallback(() => {
    const rooms = roomsHasEvents.map(filterEvents);
    const filteredRooms = rooms.filter((room) => room.destinationLocation.events.length);
    setRoomsEvent(filteredRooms);
  }, [roomsHasEvents]);

  useEffect(() => {
    filterRooms();
  }, [data]);

  useInterval(() => {
    refetch();
  }, 60000);

  const eventsList = useMemo(() => {
    const result: Array<CustomEventType> = [];

    (roomsEvents ?? []).forEach((row) => {
      const { id, destinationLocation, icon } = row;
      const { events = [], name } = destinationLocation;
      events.forEach((event) => {
        result.push({
          id,
          icon,
          name,
          event,
        });
      });
    });

    return result;
  }, [roomsEvents]);

  const sortedEvents = useMemo(
    () =>
      eventsList.sort((a, b) => {
        return new Date(a.event.start).getTime() - new Date(b.event.start).getTime();
      }),
    [eventsList],
  );

  const chunkEventsList = useMemo(() => chunk(sortedEvents, visibleRows), [sortedEvents, visibleRows]);

  const totalChunks = chunkEventsList.length;
  const isPaginationEnabled = useMemo(() => paginationEnabled && totalChunks > 1, [
    paginationEnabled,
    totalChunks,
  ]);

  useEffect(() => {
    logger('chunkEventsList', chunkEventsList);
    setActiveList(chunkEventsList[counter]);
  }, [setActiveList, chunkEventsList, counter]);

  useInterval(
    () => {
      if (counter >= totalChunks - 1) {
        setCounter(0);
      } else {
        setCounter(counter + 1);
      }
    },
    totalChunks > 1 ? interval * 1000 : null,
  );

  const WayPagination = () => (
    <Pagination
      isEnabled={isPaginationEnabled}
      counter={counter}
      totalCounter={totalChunks}
      data={pagination}
    />
  );

  return (
    <Container>
      {verticalAlignment === 'top' && <WayPagination />}
      {(activeList ?? []).map((row, index) => {
        const { name, icon, event } = row;

        const timeStart = formatDate(time(event.start).local(), 'HH:mm');
        const timeEnd = formatDate(time(event.end).local(), 'HH:mm');

        return (
          <div key={event?.id}>
            <Item separatorSize={separatorSize} separatorColor={separatorColor}>
              <ImgContainer>{eventLogo && logo && <img src={event && event?.logo?.url} />}</ImgContainer>
              <LocationName>
                <Title color={eventTitleColor}>{event && event?.title}</Title>
                <span className="wayfinding-location-name">
                  {eventTime && !onlyEvents && (
                    <Time color={eventTimeColor}>
                      {timeStart}-{timeEnd}
                    </Time>
                  )}{' '}
                  {eventLocation && <Location color={eventLocationColor}>{name}</Location>}
                </span>
              </LocationName>
              <div className="wayfinding-icon">
                <img src={icon?.url} />
              </div>
            </Item>
            {activeList.length - 1 !== index && separatorEnabled && <hr />}
          </div>
        );
      })}
      {verticalAlignment === 'bottom' && <WayPagination />}
    </Container>
  );
});

Wayfinding.displayName = 'Wayfinding';
export default Wayfinding;
