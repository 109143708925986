/* eslint-disable class-methods-use-this */
import logger from 'src/utils/logger';

export type PlayerEventType = 'ended' | 'error' | string | null;

export type VideoParamType = {
  url: string;
  orientation?: number;
  args?: { win: string; nativeLoop: boolean };
  invertVideoRotation?: boolean;
  style: { [key: string]: string };
  type?: string;
  parentElement?: HTMLElement | null;
};

abstract class Player {
  constructor(PlayerType: string) {
    logger('constructor Player', PlayerType);
  }

  async playVideo(videoParam: VideoParamType): Promise<PlayerEventType> {
    throw new Error('You must redefine "Player.playVideo" method');
  }

  async stopVideo(): Promise<void> {
    throw new Error('You must redefine "Player.stopVideo" method');
  }
}

export default Player;
