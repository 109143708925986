import React, { memo, useMemo, useEffect, useState, useRef, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import * as Types from 'src/types/main';
import logger from 'src/utils/logger';
import { getMediaFileFromLocalDB } from 'src/utils/local-db';

import { DeviceContext, SettingsContext, TemplateContext } from 'src/context';

import Clock from 'src/widget/clock/index';
import DateTime from 'src/widget/datetime';
import YouTubePlayer from 'src/widget/youtube';
import Weather from 'src/widget/weather/';
import Events from 'src/widget/events';
import FlightsWidget from 'src/widget/flights';
//
import omit from 'lodash/omit';
import RaspberryDevice from 'src/raspberry/device';
import useInterval from '../hooks/use-interval';
import Multicast from './media/multicast';

const MediaItemContainer = styled.div`
  &.fade-enter {
    opacity: 0;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-enter-active {
    opacity: 1;
  }
  &.fade-exit-active {
    opacity: 0;
  }
  &.fade-enter-active,
  &.fade-exit-active {
    transition: opacity 1500ms;
  }

  &.wrap-datetime {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

type MediaItemComponentProps = {
  data?: Types.MediaItemType;
  style?: { [key: string]: string };
  loopVideo?: boolean;
  onPlaybackEnd?: () => void;
};

const DEFAULT_VIDEO_EXTENSION = 'mp4';

export const MediaItemComponent: React.FC<MediaItemComponentProps> = (props) => {
  const device = useContext(DeviceContext);
  const settings = useContext(SettingsContext);
  const template = useContext(TemplateContext);

  logger('MediaItemComponent');

  const fullscreen = !template;
  const { orientation, invertVideoRotation } = settings || {};
  const { data, style, onPlaybackEnd, loopVideo } = props;
  const isDownloadableFile = device && ['video', 'image'].includes(data?.type || '');
  const containerRef = useRef<HTMLDivElement | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [mediaFile, setMediaFile] = useState<Types.MediaFileType | null>(null);

  const { type, id, title } = data || {};
  const { deviceType } = window;

  useEffect(() => {
    setMediaFile(null);
    if (isDownloadableFile && data?.id) {
      logger('Getting new media-item file', { id: data.id });
      setMediaFile(getMediaFileFromLocalDB(data.id));
    }
  }, [data?.id]);

  const onPlayVideo = useCallback(() => {
    logger('onPlayVideo', { deviceType, videoRef: videoRef.current });
    if (deviceType === 'web0s' || videoRef.current) {
      logger('Playing video');
      // @ts-ignore
      videoRef.current?.setAttribute('texture', true);
    }
  }, [deviceType, videoRef]);

  // Check each 0.5 seconds if file has been downloaded (if during previous render it's not)
  useInterval(
    async () => {
      if (isDownloadableFile && data?.id) {
        logger('Getting new media-item file (by interval)', { id: data.id });
        setMediaFile(getMediaFileFromLocalDB(data.id));
      }
    },
    // Delay in milliseconds or null to stop it
    !mediaFile ? 500 : null,
  );

  useEffect(() => {
    if (device instanceof RaspberryDevice) {
      device.socket.on('video-ended', (res) => {
        logger('ended playVideo Promise');
        if (!loopVideo && typeof onPlaybackEnd === 'function') {
          logger('call onPlaybackEnd', { loopVideo });
          onPlaybackEnd();
        }
      });
      return () => {
        logger('useEffect unsubscribe video-ended');
        device.socket.off('video-ended');
      };
    }
    return () => {};
  }, [loopVideo, onPlaybackEnd]);

  useEffect(() => {
    if (!device || type?.toLowerCase() === 'multicast') {
      return;
    }
    logger('call stop video media-item');
    device.player.stopVideo().catch(logger);
  }, [data?.id, typeof device?.player?.stopVideo]);

  const component = useMemo(() => {
    if (!data || !device || !settings) {
      logger('Invalid data passed to "createMediaElement function"', {
        level: 'warn',
        meta: {
          data,
        },
      });
      return null;
    }
    if (isDownloadableFile) {
      // If we failed to get media-file from local-db
      // or if media-file doesn't match current mediaItem's id
      // we should return null instead of media-item's content
      if (!mediaFile || mediaFile.id !== id) {
        return null;
      }
    }
    const { url } = mediaFile || data;
    const { extension = DEFAULT_VIDEO_EXTENSION } = mediaFile || {};

    switch (type?.toLowerCase()) {
      case 'video': {
        if (deviceType === 'arm' || deviceType === 'tizen') {
          logger('Render video on ARM/Tizen', {
            debugData: JSON.stringify({ mediaFile, type, data }),
          });
          device.player
            .playVideo({
              url,
              orientation,
              invertVideoRotation,
              args: {
                nativeLoop: loopVideo,
              },
              style: style || {},
              parentElement: containerRef.current,
            })
            // This promise will be resolved with some result
            // only on Tizen/WebOS/Chrome devices
            .then((res) => {
              if (res === 'ended') {
                logger('ended playVideo Promise');
                if (!loopVideo && typeof onPlaybackEnd === 'function') {
                  onPlaybackEnd();
                }
              }
              if (res === 'error') {
                logger('error playVideo Promise');
                // !ToDo: Render error-message on the screen
                // @ts-ignore
                window.timeoutId = setTimeout(() => {
                  if (typeof onPlaybackEnd === 'function') {
                    onPlaybackEnd();
                  }
                }, 20000);
              }
            })
            .catch(console.error);
          //
          return null;
        }
        return (
          <video
            ref={videoRef}
            id={id}
            autoPlay
            onEnded={onPlaybackEnd}
            onPlay={onPlayVideo}
            loop={loopVideo}
            src={url}
            // @ts-ignore
            type={`video/${extension}`}
            className={`${deviceType}-${fullscreen ? 'fullscreen' : 'no-fullscreen'}-${settings.orientation}`}
          />
        );
      }
      case 'icon':
      case 'logo':
      case 'image': {
        let imgStyle = {};
        let imgClassName = '';

        if (deviceType === 'arm' && (settings.orientation === 90 || settings.orientation === 270)) {
          imgStyle = { objectFit: 'unset', maxWidth: '100%' };
        }

        if (type?.toLowerCase() === 'logo') {
          imgStyle = { objectFit: 'contain', height: 'inherit' };
        }

        imgClassName = deviceType;
        imgClassName += fullscreen ? '-fullscreen' : '';
        imgClassName += `-${settings.orientation}`;

        return <img id={id} src={url} alt={title} className={imgClassName} style={imgStyle} />;
      }
      case 'iframe': {
        return <iframe id={id} scrolling="no" src={url} />;
      }
      case 'hls':
      case 'multicast':
        return (
          <Multicast
            parentContainer={containerRef}
            id={id}
            loopVideo={loopVideo}
            deviceType={deviceType}
            url={url}
            style={style}
            type={type}
          />
        );
      case 'youtube': {
        return (
          <div style={{ objectFit: 'contain' }}>
            <YouTubePlayer item={data} style={style} />
          </div>
        );
      }
      case 'datetime': {
        return <DateTime item={data} style={style} />;
      }
      case 'clock': {
        return (
          <div style={{ objectFit: 'contain' }}>
            <Clock item={data} style={style} />
          </div>
        );
      }
      case 'weather': {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <Weather item={data} />
          </div>
        );
      }
      case 'location-wayfinding':
      case 'location-events':
      case 'location-header':
      case 'location-event-logo': {
        return <Events data={data} />;
      }
      case 'flights':
        return <FlightsWidget data={data} />;
      default:
        return <div>Unknown media-item type</div>;
    }
  }, [type, data, mediaFile, isDownloadableFile, onPlaybackEnd]);
  //
  const handleItemTransition = useCallback((node, done) => {
    node.addEventListener('transitionend', done, false);
  }, []);
  //
  const blacklistStyle = ['backgroundColor'];
  const filteredStyle = omit(style, blacklistStyle);
  //
  const innerContent = (
    <MediaItemContainer
      id={`container-${id}`}
      ref={containerRef}
      style={filteredStyle}
      className={`${type}-${orientation} wrap-${type}${fullscreen ? '-fullscreen' : ''} ${deviceType}`}
    >
      {component}
    </MediaItemContainer>
  );
  // We don't need rotation animation if previous/next mediaItem has 'video' type
  if (type === 'video' || !fullscreen) {
    return innerContent;
  }

  return (
    <SwitchTransition mode="in-out">
      <CSSTransition key={id} addEndListener={handleItemTransition} classNames="fade">
        {innerContent}
      </CSSTransition>
    </SwitchTransition>
  );
};
MediaItemComponent.displayName = 'MediaItemComponent';
MediaItemComponent.propTypes = {
  loopVideo: PropTypes.bool,
  onPlaybackEnd: PropTypes.func,
};
MediaItemComponent.defaultProps = {
  loopVideo: false,
  onPlaybackEnd: undefined,
};

export default memo(MediaItemComponent) as typeof MediaItemComponent;
