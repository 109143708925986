/* eslint-disable func-names, prefer-promise-reject-errors, class-methods-use-this */
import Device, { DevicePropsType } from 'src/class/control-device';
import logger from 'src/utils/logger';
import Store from './store';
import { ChromiumPlayer } from './player';

class ChromiumDevice extends Device {
  store: Store;

  startSchedule;

  constructor(props: DevicePropsType) {
    super(props);
    logger('constructor ChromiumDevice', props);
    this.startSchedule = props.startSchedule;
    this.player = new ChromiumPlayer(props.deviceType, this);
    this.store = new Store(props.deviceType, this);
  }

  async sendData(emitName: string, data?: unknown) {
    logger('sendData');
    return 'nothing';
  }

  reboot(): void {
    logger('Reboot chrome');
  }

  reload(): void {
    window.location.reload();
  }

  async printscreen() {
    logger('printscreen');
  }

  setTimezone(timezone: string = 'Europe/Moscow') {
    return new Promise((resolve, reject) => {
      logger('setTimezone', timezone);
      resolve(false);
    });
  }

  async getVersion() {
    logger('getVersion');
    return {};
  }

  async getNetwork() {
    logger('function getNetwork');
    return {
      ip: '0.0.0.0',
      mac: 'FF:FF:FF:FF:FF:FF',
    };
  }

  updateFirmware(version: string) {
    this.reload();
  }

  wifiConfig() {
    logger('function wifiConfig');
  }
}

export default ChromiumDevice;
