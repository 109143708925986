/* eslint-disable class-methods-use-this */
import { Store, UsedMemoryType, FileDataType, getAndWriteType } from 'src/class/store';
import { TODO_ANY } from 'src/types/main';
import logger from 'src/utils/logger';
import { Promise as promise } from 'bluebird';
import Device from './device';

const CustomEvent = require('custom-event');
//
class Web0sStore extends Store {
  device: Device;

  prevPercent: number = 0;

  storage = new Storage();

  constructor(params: string, device: Device) {
    super(params, device);
    console.log('Web0s device');
    this.device = device;
  }

  getFileSize(fileUrl: string): Promise<number> {
    const xhr = new XMLHttpRequest();
    return new promise((resolve, reject) => {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === xhr.DONE) {
          if (xhr.status === 200 || xhr.status === 201) {
            const contentLength = xhr.getResponseHeader('Content-Length') || '0';
            const totalFileSize = parseInt(contentLength, 10);

            console.log('totalFileSize', totalFileSize);

            resolve(totalFileSize);
          } else {
            console.error(xhr.status);
          }
        }
      };
      // HEAD request is much lighter than GET request to get content-length
      xhr.open('HEAD', fileUrl);
      xhr.send();
    });
  }

  updateProgress(path: string) {
    function checkProgress(cbObject: TODO_ANY) {
      return cbObject.size;
    }

    const failureCb = (cbObject: TODO_ANY) => {
      const { errorCode, errorText } = cbObject;
      console.log(` Error Code [${errorCode}]: ${errorText}`);
    };

    this.storage.statFile(checkProgress, failureCb, { path });
  }

  getUsedMemory(): void {
    // return new Promise((resolve, reject) => {
    //   const used = tizen.systeminfo.getTotalMemory();
    //   const remaining = tizen.systeminfo.getAvailableMemory();
    //   resolve({ used, remaining });
    // });
  }

  purge(): Promise<void> {
    return new promise((resolve) => {
      this.storage.removeAll(
        function successCb() {
          console.log('Removing all files done.');
          resolve();
        },
        function failureCb(cbObject: TODO_ANY) {
          const { errorCode, errorText } = cbObject ?? {};
          console.log(` Error Code [${errorCode}]: ${errorText}`);
          resolve();
        },
        { device: 'internal' },
      );
    });
  }

  deleteFile(path: string, url: string) {
    return new promise((resolve) => {
      this.storage.removeFile(
        function successCb() {
          console.log('Removing File done.');
          resolve();
        },
        function failureCb(cbObject: TODO_ANY) {
          const { errorCode, errorText } = cbObject ?? {};
          console.log(` Error Code [${errorCode}]: ${errorText}`);
          resolve();
        },
        { file: path },
      );
    });
  }

  getAndWrite(fileData: FileDataType): Promise<getAndWriteType> {
    const { type, id: ID, path } = fileData;
    const { url } = fileData;
    const extension = url.split('.').pop() || '';
    const [mime] = path.split('/');
    const remoteMediaId = url.split('/').slice(-2).join('/');
    const that = this;
    const storePath = `file://internal/${path}`;
    const fullPath = `http://127.0.0.1:9080/${path}`;

    return this.getFileSize(url).then((totalBytes) => {
      logger('webOS.getAndWrite.totalBytes', { url, totalBytes });
      return new promise((resolve, reject) => {
        const options = {
          source: url,
          destination: storePath,
        };

        const successCb = () => {
          logger('webOS.getAndWrite.download file complete', { fullPath, storePath });
          that.files.set(ID, { status: 'downloaded', loaded: totalBytes, total: totalBytes });
          logger('webOS.getAndWrite.files.set');
          const result = {
            id: ID,
            fullPath,
            storePath,
          };
          logger('webOS.getAndWrite.success');
          resolve(result);
          that.listener.dispatchEvent(new CustomEvent('load-percent'));
        };

        const failureCb = (cbObject: TODO_ANY) => {
          const { errorCode, errorText } = cbObject;
          console.error(` Error Code [${errorCode}]: ${errorText}`, { cbObject });
          that.files.set(ID, { status: 'error', loaded: 0, total: totalBytes });
          reject(errorText);
        };

        logger('start download');
        that.files.set(ID, { status: 'loading', loaded: 0, total: totalBytes });
        this.storage.copyFile(successCb, failureCb, options);
      });
    });
  }
}

export default Web0sStore;
