import React, { FC, useContext, MutableRefObject, useEffect, useRef } from 'react';
import * as Types from 'src/types/main';
import logger from 'src/utils/logger';
import { DeviceContext, SettingsContext } from 'src/context';

interface IMulticastProps {
  id?: string;
  parentContainer: MutableRefObject<HTMLDivElement | null>;
  url: string;
  type: string;
  loopVideo?: boolean;
  deviceType: Types.DeviceType;
  style?: Types.StyleType;
}

const Multicast: FC<IMulticastProps> = ({ id, url, parentContainer, style, type, loopVideo, deviceType }) => {
  const device = useContext(DeviceContext);
  const settings = useContext(SettingsContext);
  const ref = useRef<HTMLVideoElement | null>(null);

  const { orientation } = settings || {};

  let sourceType = 'video/mp4';

  if (deviceType === 'web0s') {
    const options = {
      htmlMediaOption: {
        useUMSMediaInfo: true,
      },
      mediaTransportType: 'UDP',
    };

    sourceType = `${sourceType};mediaOption=${encodeURI(JSON.stringify(options))}`;
  }

  useEffect(() => {
    if (ref.current && deviceType === 'web0s') {
      ref.current.addEventListener(
        'umsmediainfo',
        (e: any) => {
          try {
            const eventCheck = JSON.parse(e.detail);

            if (eventCheck?.info?.errorCode === 0) {
              console.log('[LG] Stremaing play with no error');
            }
            if (eventCheck?.info === 'playing') {
              console.log('[LG] Playing started.');
            }
            if (eventCheck?.info?.udp?.errorText === 'UDP Streaming Error') {
              console.log('[LG] UDP Streaming Error');
            }
          } catch (err: any) {
            console.log('[LG] error play', err);
          }
        },
        false,
      );

      ref.current.load();
      ref.current.play();
    }
  }, [ref]);

  if (deviceType === 'tizen' || deviceType === 'arm') {
    device?.player
      .playVideo({
        url,
        orientation,
        args: {},
        style: style || {},
        type,
        parentElement: parentContainer.current,
      })
      .then((res) => {
        if (res === 'ended') {
          logger('ended playVideo Promise');
          const videoElement = document.querySelector('video')!;
          if (!loopVideo) {
            videoElement.dispatchEvent(new Event(res));
          }
        }
        if (res === 'error') {
          logger('error playVideo Promise');
          const videoElement = document.querySelector('video')!;
          setTimeout(() => {
            videoElement.dispatchEvent(new Event('ended'));
          }, 20000);
        }
      })
      .catch(logger);

    return null;
  }

  return (
    <video id={id} ref={ref} autoPlay className={`${deviceType}-no-fullscreen`}>
      <source src={url} type={sourceType} />
    </video>
  );
};

export default Multicast;
