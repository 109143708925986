import assign from 'lodash/assign';
import Device from 'src/class/control-device';
import * as Types from 'src/types/main';
import logger from './logger';

export type LocalDBItem = {
  [k: string]: unknown;
};

export function getItemFromDB<T extends LocalDBItem | LocalDBItem[]>(id: string, defaultValue?: T): T {
  const value = window.localStorage.getItem(id);
  if (value) {
    return JSON.parse(value);
  }
  return (defaultValue || null) as T;
}

export function saveItemToDB<T extends LocalDBItem | LocalDBItem[]>(
  id: string,
  value: Partial<T> | null,
  defaultValue?: T,
): T {
  if (!id || typeof id !== 'string') {
    throw new Error('Invalid "id" passed to localDB.saveItemToDB method');
  }
  if (value === null) {
    if (defaultValue && typeof defaultValue !== 'object') {
      throw new Error(`Invalid "defaultValue" passed to localDB.saveItemToDB method`);
    }
    if (defaultValue) {
      window.localStorage.setItem(id, JSON.stringify(defaultValue));
    } else {
      window.localStorage.removeItem(id);
    }
    return defaultValue as T;
  }
  if (!value || typeof value !== 'object') {
    throw new Error('Invalid "value" passed to localDB.saveItemToDB method');
  }
  const oldValue = getItemFromDB<T>(id, defaultValue);
  const newValue: T = Array.isArray(value)
    ? (value as T)
    : assign({}, defaultValue || {}, oldValue || {}, value);

  window.localStorage.setItem(id, JSON.stringify(newValue));

  return newValue;
}

const deviceType = Device.getType();

const DEFAULT_SETTINGS: Types.SettingsType = {
  orientation: 0,
  debug: false,
  deviceName: deviceType,
  deviceType,
  scheduleUpdate: new Date(),
  rawSchedule: {},
  timezone: 'Europe/Moscow',
  waitConnect: false,
  invertVideoRotation: false,
  locationId: null,
  absTzOffset: null,
  resolution: {
    width: 1920,
    height: 1080,
  },
  network: {
    lan: {},
    wifi: {},
    vpn: {},
  },
};

export function getSettingsFromLocalDB(): Types.SettingsType {
  return getItemFromDB('settings', DEFAULT_SETTINGS);
}

export function updateSettingsInLocalDB(settings: Partial<Types.SettingsType> | null): Types.SettingsType {
  return saveItemToDB<Types.SettingsType>('settings', settings, DEFAULT_SETTINGS);
}

export function getDefaultMediaFromLocalDB(): Types.MediaItemType | null {
  return getItemFromDB<Types.MediaItemType>('default');
}

export function updateDefaultMediaInLocalDB(
  defaultMedia: Partial<Types.MediaItemType> | null,
): Types.MediaItemType | null {
  return saveItemToDB<Types.MediaItemType>('default', defaultMedia);
}

export function getWeekdayScheduleFromLocalDB(): Types.ScheduleType | null {
  return getItemFromDB<Types.ScheduleType>('schedule');
}

export function updateWeekdayScheduleInLocalDB(
  schedule: Partial<Types.ScheduleType> | null,
): Types.ScheduleType | null {
  return saveItemToDB<Types.ScheduleType>('schedule', schedule);
}

export function getEverydayScheduleFromLocalDB(): Types.TimeslotType[] {
  return getItemFromDB<Types.TimeslotType[]>('everyday', []);
}

export function updateEverydayScheduleInLocalDB(
  timeslots: Types.TimeslotType[] | null,
): Types.TimeslotType[] {
  return saveItemToDB<Types.TimeslotType[]>('everyday', timeslots, []);
}

export function getEventsScheduleFromLocalDB(): Types.TimeslotType[] {
  return getItemFromDB<Types.TimeslotType[]>('events', []);
}

export function updateEventsScheduleInLocalDB(timeslots: Types.TimeslotType[] | null): Types.TimeslotType[] {
  return saveItemToDB<Types.TimeslotType[]>('events', timeslots, []);
}

export function getMediaFilesFromLocalDB(): Types.MediaFileType[] {
  return getItemFromDB<Types.MediaFileType[]>('files', []);
}

export function getMediaFileFromLocalDB(id: string): Types.MediaFileType | null {
  const files = getItemFromDB<Types.MediaFileType[]>('files', []);
  return files.find((file) => file.id === id) || null;
}

export function updateMediaFilesInLocalDB(files: Types.MediaFileType[] | null): Types.MediaFileType[] {
  return saveItemToDB<Types.MediaFileType[]>('files', files, []);
}

export function addMediaFileToLocalDB(mediaFile: Types.MediaFileType): Types.MediaFileType[] {
  const files = getMediaFilesFromLocalDB();
  let fileListUpdated = false;
  const fileList = files.map((file) => {
    if (file.id === mediaFile.id) {
      fileListUpdated = true;
      return mediaFile;
    }
    return file;
  });
  if (!fileListUpdated) {
    fileList.push(mediaFile);
  }
  return updateMediaFilesInLocalDB(fileList);
}

export function removeMediaFileFromLocalDB(id: string): Types.MediaFileType[] {
  const files = getMediaFilesFromLocalDB();
  const updatedFileList = files.filter((file) => file.id !== id);
  return updateMediaFilesInLocalDB(updatedFileList);
}
