export const weekdays: { [k: number]: string } = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

// Flights widget
export const FLIGHT_STATUS = {
  Canceled: 'CANCELED',
  Delayed: 'DELAYED',
  Landed: 'LANDED',
  Scheduled: 'SCHEDULED',
};
export const FLIGHTS_DIRECTION = {
  Arrivals: 'ARRIVALS',
  Departures: 'DEPARTURES',
};
export const DEFAULT_FLIGHT_WIDGET_ROTATION_DURATION = 30000;
export const MIN_FLIGHT_WIDGET_ROTATION_DURATION = 10000;
