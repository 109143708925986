/* eslint-disable class-methods-use-this */
/* eslint-disable func-names */
import { Store, FileEntryType, UsedMemoryType, FileDataType, getAndWriteType } from 'src/class/store';
import { ChromeStore } from 'src/library/chromestore';
import Device from 'src/class/control-device';

export type RaspberryFileEntryFlags = { create?: boolean };

export type LoadFileParams = {
  id: string;
  url: string;
  path: string;
  fileType: string;
  flags: RaspberryFileEntryFlags;
};

class ChromiumStore extends Store {
  device: Device;

  prevPercent: number = 0;

  // constructor(params: string) {
  constructor(params: string, device: Device) {
    super(params, device);
    try {
      // @ts-ignore
      this.cs = new ChromeStore([{ path: 'video' }, { path: 'image' }]);
      this.cs.init(500024 * 1024 * 1024);
      this.device = device;
    } catch (e) {
      console.error('ChromeStore error:', e);
    }
  }

  async getUsedMemory(): Promise<UsedMemoryType> {
    return new Promise((resolve, reject) => {
      try {
        this.cs.usedAndRemaining((used: number, remaining: number) => {
          resolve({ used, remaining });
        });
      } catch (e) {
        console.error('Chrome usedAndRemaining error:', e);
      }
    });
  }

  async purge(): Promise<void> {
    try {
      await this.cs.purge();
    } catch (e) {
      console.error('Chrome purge error:', e);
    }
  }

  async deleteFile(path: string) {
    if (path.length === 0) {
      return;
    }
    console.log('chrome deleteFile', { path });
    try {
      await this.cs.deleteFile(path);
    } catch (e) {
      console.error('Chrome deleteFile error:', e);
    }
  }

  async loadFile(fileData: LoadFileParams): Promise<FileEntryType> {
    const that = this;
    const { id, url } = fileData;
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'arraybuffer';
    xhr.send();
    //
    return new Promise((resolve, reject) => {
      xhr.onloadstart = function (event) {
        that.files.set(id, { status: 'loading', loaded: 0, total: event.total });
        // console.log('onloadstart', progressDownload, event);
      };
      xhr.onprogress = function (event) {
        that.files.set(id, { status: 'loading', loaded: event.loaded, total: event.total });
        that.listener.dispatchEvent(new Event('load-percent'));
        // this.store.listener.dispatchEvent(new CustomEvent('file-load', { detail: { percent } }));
        // console.log('onprogress', progressDownload, event.loaded, event.total);
      };

      xhr.onloadend = function (event) {
        // if (this.status === 200) {
        that.files.set(id, { status: 'downloaded', loaded: event.loaded, total: event.total });
        that.listener.dispatchEvent(new Event('load-percent'));
        resolve(this.response);
        // } else {
        //   reject(event);
        // }
      };

      xhr.onerror = function (err) {
        // !TODO Error
        console.log('error xhr');
        reject(err);
        //
      };
    });
  }

  async getAndWrite(fileData: FileDataType): Promise<getAndWriteType> {
    const { id, url, path, fileType, flags } = fileData;
    const data = await this.loadFile({ id, url, path, fileType, flags });
    const fileEntry: FileEntryType = await new Promise((resolve, reject) => {
      try {
        this.cs.write(path, fileType, data, flags, (fEntry: FileEntryType) => {
          console.log('ChromeStore.writeCallback', fEntry, { path, fileType, data });
          resolve(fEntry);
        });
      } catch (e) {
        console.error('Chrome write error:', e);
      }
    });
    //
    return { id, fullPath: fileEntry.toURL(), storePath: fileEntry.toURL() };
  }
}

export default ChromiumStore;
