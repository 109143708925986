/* eslint-disable func-names, prefer-promise-reject-errors, class-methods-use-this */
import Player, { PlayerEventType, VideoParamType } from 'src/class/player';
import isEmpty from 'lodash/isEmpty';
import { Promise as promise } from 'bluebird';
import Device from './device';

function round(num: number): number {
  const roundNum = Math.round(num);
  if (roundNum < 0) {
    return Math.abs(roundNum);
  }
  return roundNum;
}

export class TizenPlayer extends Player {
  device: Device;

  objElem: HTMLObjectElement;

  bitRateString;

  constructor(params: string, device: Device) {
    super(params);
    this.device = device;
    this.objElem = document.createElement('object');
    this.objElem.type = 'application/avplayer';
    this.objElem.id = 'av-player';
    document.body.appendChild(this.objElem);
    this.bitRateString =
      'BITRATES=5000~10000|FIXED_MAX_RESOLUTION=3840x2160|STARTBITRATE=LOWEST|START_TIME=1000';
  }

  playVideo(videoParam: VideoParamType): Promise<PlayerEventType> {
    const { url, orientation = 0, invertVideoRotation, args, type, style } = videoParam;
    const playerWrap = document.getElementById('av-player')!;
    const { left, top, width, height } = style;
    //
    const leftInt = left ? round(parseInt(left, 10)) : 0;
    const topInt = top ? round(parseInt(top, 10)) : 0;
    const widthInt = width ? parseInt(width, 10) : 1920;
    const heightInt = height ? parseInt(height, 10) : 1080;
    //
    const leftStyle = `${leftInt}px`;
    const topStyle = `${topInt}px`;
    const widthStyle = widthInt === 1920 ? '100%' : `${widthInt}px`;
    const heightStyle = heightInt === 1080 ? '100%' : `${heightInt}px`;
    //
    let avplayerLeft = leftInt;
    let avplayerTop = topInt;
    let avplayerWidth = widthInt;
    let avplayerHeight = heightInt;
    // Invert video for Samsung TV
    let correctOrientation = orientation;
    if (invertVideoRotation) {
      if (orientation === 90) {
        correctOrientation = 270;
      }
      if (orientation === 270) {
        correctOrientation = 90;
      }
    }
    return new Promise((resolve, reject) => {
      const isLoop: boolean = args?.nativeLoop || false;
      switch (orientation) {
        case 90:
          avplayerLeft = isEmpty(style) ? leftInt : window.screen.width - (topInt + heightInt);
          avplayerTop = isEmpty(style) ? topInt : leftInt;
          avplayerWidth = isEmpty(style) ? widthInt : heightInt;
          avplayerHeight = isEmpty(style) ? heightInt : widthInt;
          break;
        case 180:
          avplayerLeft = isEmpty(style) ? leftInt : window.screen.height - (topInt + heightInt);
          avplayerTop = isEmpty(style) ? topInt : window.screen.width - (leftInt + widthInt);
          break;
        case 270:
          avplayerLeft = isEmpty(style) ? leftInt : topInt;
          avplayerTop = isEmpty(style) ? topInt : window.screen.height - (leftInt + widthInt);
          avplayerWidth = isEmpty(style) ? widthInt : heightInt;
          avplayerHeight = isEmpty(style) ? heightInt : widthInt;
          break;
        default:
          //
          break;
      }
      console.log(
        'player coordinates',
        {
          avplayerLeft,
          avplayerTop,
          avplayerWidth,
          avplayerHeight,
          leftInt,
          topInt,
          heightInt,
          widthInt,
        },
        'window.screen.width',
        window.screen.width,
        'window.screen.height',
        window.screen.height,
      );
      try {
        const listener = {
          onbufferingstart() {
            console.log('Buffering start.');
          },

          onbufferingprogress(percent: number) {
            console.log(`Buffering progress data : ${percent}`);
          },

          onbufferingcomplete() {
            console.log('Buffering complete.');
          },

          onerror(eventType: string) {
            console.log(`event type error : ${eventType}`);
          },

          onstreamcompleted() {
            console.log('[tizen] stream Completed, isLoop', { isLoop });
            if (isLoop) {
              console.log('[tizen] video is looping');
              webapis.avplay.stop();
              webapis.avplay.prepare();
              webapis.avplay.play();
            } else {
              console.log('[tuzen] stop video');
              webapis.avplay.stop();
              webapis.avplay.close();
              playerWrap.setAttribute('style', 'display:none;');
              resolve('ended');
            }
          },

          // oncurrentplaytime(currentTime) {
          //   console.log(`Current playtime: ${currentTime}`);
          // },

          // onevent(eventType: string, eventData: string) {
          // console.log(`event type: ${eventType}, data: ${eventData}`);
          // },

          // onsubtitlechange(duration, text: string, data3, data4) {
          //   console.log(`subtitleText: ${text}`);
          // },
        };

        playerWrap.setAttribute(
          'style',
          `display:block; top:${topStyle}; left:${leftStyle}; width:${widthStyle}; height:${heightStyle};`,
        );

        if (type?.toLowerCase() === 'multicast') {
          const drmParam = {
            LYNKServer: 'NONE', // No LYNK server required
            type: '1',
          };
          webapis.avplay.close();
          webapis.avplay.open(url);
          webapis.avplay.setDisplayRect(avplayerLeft, avplayerTop, avplayerWidth, avplayerHeight);
          webapis.avplay.setListener(listener);
          // webapis.avplay.setTimeoutForBuffering(10);
          // For the initial buffering
          webapis.avplay.setBufferingParam('PLAYER_BUFFER_FOR_PLAY', 'PLAYER_BUFFER_SIZE_IN_SECOND', 5); // 5 is in seconds
          // For the rebuffering
          webapis.avplay.setBufferingParam('PLAYER_BUFFER_FOR_RESUME', 'PLAYER_BUFFER_SIZE_IN_SECOND', 15); // 15 is in seconds
          // @ts-ignore
          // webapis.b2brtpplay.setDrm('LYNK', 'Initialize', JSON.stringify(drmParam));
          webapis.avplay.prepareAsync(function () {
            webapis.avplay.play();
            // @ts-ignore
            webapis.avplay.setDisplayRotation(`PLAYER_DISPLAY_ROTATION_${correctOrientation}`);
          });
          console.log('[Tizen] play multicast');
          resolve(null);
        } else {
          webapis.avplay.close();
          if (type?.toLowerCase() === 'hls') {
            webapis.avplay.setStreamingProperty('ADAPTIVE_INFO', this.bitRateString);
            webapis.avplay.setStreamingProperty('PREBUFFER_MODE', '10000');
          }
          webapis.avplay.open(url);
          webapis.avplay.setListener(listener);
          webapis.avplay.setDisplayRect(avplayerLeft, avplayerTop, avplayerWidth, avplayerHeight);
          webapis.avplay.prepareAsync(function () {
            console.log('Get state before play', webapis.avplay.getState());
            webapis.avplay.play();
            // @ts-ignore
            webapis.avplay.setDisplayRotation(`PLAYER_DISPLAY_ROTATION_${correctOrientation}`);
          });
        }
      } catch (e) {
        console.log('error play', e);
      }
    });
  }

  stopVideo(): Promise<undefined> {
    return new promise((resolve, reject) => {
      if (webapis.avplay.getState() === 'PLAYING') {
        webapis.avplay.stop();
        webapis.avplay.close();
        const playerWrap = document.getElementById('av-player')!;
        console.log('playerWrap', playerWrap);
        playerWrap.setAttribute('style', 'display:none;');
        console.log('stop video tizen');
      }
      resolve(undefined);
    });
  }
}
export default TizenPlayer;
