/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
import { Promise as promise } from 'bluebird';
import Device, { DevicePropsType } from 'src/class/control-device';
import { GetNetworkType } from 'src/types/main';
import logger from 'src/utils/logger';
import { IDeviceMetadata, IWebosMetadata } from 'src/types/device-metadata';
import Web0sStore from './store';
import { Web0sPlayer } from './player';

type errorType = {
  errorCode: string;
  errorText: string;
};

interface ITimezone {
  continent: string;
  country: string;
  city: string;
}

type InterfaceType = {
  state: 'connected' | 'disconnected';
  interfaceName?: string;
  ipAddress?: string;
  netmask?: string;
  gateway?: string;
  onInternet?: 'yes' | 'no';
  method?: 'dhcp' | 'manual';
  dns1?: string;
  dns2?: string;
};

type getNetworkInfoType = {
  isInternetConnectionAvailable: boolean;
  wired: InterfaceType;
  wifi: InterfaceType;
};

type getNetworkMacInfoType = {
  wifiInfo: {
    macAddress: string;
  };
  wiredInfo: {
    macAddress: string;
  };
};

class Web0sDevice extends Device {
  store: Web0sStore;

  appVersion: string;

  servAppVersion: string;

  // @ts-ignore
  configuration;
  // @ts-ignore
  power;
  // @ts-ignore
  signage;
  // @ts-ignore
  deviceInfo;
  // @ts-ignore
  custom;
  // @ts-ignore
  idcap;

  constructor(props: DevicePropsType) {
    super(props);
    this.initialized = false;
    // @ts-ignore
    logger('Constructor Web0s');
    this.startSchedule = props.startSchedule;
    this.appVersion = props.packageVersion.split('.').join('');
    this.init(props);
  }

  init(props: DevicePropsType) {
    this.player = new Web0sPlayer(props.deviceType, this);
    this.store = new Web0sStore(props.deviceType, this);
    // @ts-ignore
    this.deviceInfo = new DeviceInfo();
    // @ts-ignore
    this.configuration = new Configuration();
    // @ts-ignore
    this.power = new Power();
    // @ts-ignore
    this.signage = new Signage();
    this.getVersion();
    this.debugMode();
  }

  failureCb(cbObject: errorType) {
    const { errorCode, errorText } = cbObject;
    logger(`Error Code [${errorCode}]: ${errorText}`);
  }

  updateFirmware(version: string): void {
    const options = {
      // @ts-ignore
      type: Storage.AppType.ZIP,
      // @ts-ignore
      to: Storage.AppMode.LOCAL,
      recovery: false,
    };

    const successCb = () => {
      this.reboot();
    };

    this.store.storage.upgradeApplication(successCb, this.failureCb, options);
  }

  hostLink() {
    switch (process.env.API_HOST) {
      case 'st-portal-staging.fjedi.com':
        return 'st-portal-staging.fjedi.com';
      case 'dsu.fjedi.com':
        return 'dsu.fjedi.com';
      case 'ds.hoteza.com':
        return 'ds.hoteza.com';
      case 'ovds.gtkcentral.net':
        return 'ds.gtkcentral.net';
      default:
        return 'fjedi.com';
    }
  }

  downloadFile(fileUrl: string, filePath: string): Promise<string> {
    return new promise((resolve, reject) => {
      const downloadOptions = {
        source: fileUrl,
        destination: `file://internal/${filePath}`,
      };

      const downloadComplete = () => {
        logger('Copying File done.');
        resolve(downloadOptions.destination);
      };

      const downloadFailed = (error: errorType) => {
        const { errorCode, errorText } = error;
        logger(`Error Code [${errorCode}]: ${errorText}`);
        reject(errorText);
      };

      // Download file from remote
      this.store.storage.copyFile(downloadComplete, downloadFailed, downloadOptions);
    });
  }

  debugMode(): void {
    // @ts-ignore
    const configuration = new Configuration();
    // options.enabled = true; //enabling debug mode
    configuration.debug(
      function successCb() {
        logger('Succeeded to enable the debug mode');
      },
      function failureCb() {
        logger('Failed to enable the debug mode');
      },
      { enabled: true },
    );
  }

  reboot(): void {
    const onError = (error: Error & { code: string }) => {
      logger(`[rebootDevice] code :${error.code} error name: ${error.name} message ${error.message}`);
    };
    logger('[rebootDevice] ');
    // @ts-ignore
    this.power.executePowerCommand(() => {}, onError, { powerCommand: Power.PowerCommand.REBOOT });
  }

  reload(): void {
    window.location.reload();
  }

  printscreen(): Promise<string> {
    return new promise((resolve, reject) => {
      const options = {
        save: true,
        thumbnail: false,
      };

      const success = (result: any) => {
        const { size, encoding, data } = result;
        logger('printscreen', { size, encoding, data });
        // Saved as: 0_captured_by_scap.jpg
        // Saved to: The media directory and content directory of the local storage.
        resolve(`data:image/jpg;base64,${data}`);
      };

      const failure = (error: errorType) => {
        const { errorCode, errorText } = error;
        console.error(`getNetwork Error Code ${errorCode} message ${errorText}`);
        reject(null);
      };

      try {
        this.signage.captureScreen(success, failure, options);
      } catch (e) {
        reject('Error signage api');
        console.error('this.signage.captureScreen', e);
      }
    });
  }

  getDeviceInfo(): Promise<IWebosMetadata | undefined> {
    return new promise((resolve, reject) => {
      const onSuccess = (result: any) => {
        logger('WebOS version:', result);
        resolve(result);
      };

      const onFailure = (error: errorType) => {
        const { errorCode, errorText } = error;
        console.error(`getVersion Error Code ${errorCode} message ${errorText}`);
        reject(errorText);
      };

      try {
        this.deviceInfo.getPlatformInfo(onSuccess, onFailure);
      } catch (e) {
        console.error('deviceInfo.getPlatformInfo error', e);
        resolve();
      }
    });
  }

  async getVersion(): Promise<IDeviceMetadata> {
    const deviceInfo = await this.getDeviceInfo();
    const webOs = await getWebOsVersion();

    const model = deviceInfo?.modelName;
    delete deviceInfo?.modelName;

    return {
      osVersion: webOs,
      model,
      ...deviceInfo,
    };
  }

  getIpAddress(): Promise<getNetworkInfoType | null> {
    return new promise((resolve, reject) => {
      const onSuccess = (result: getNetworkInfoType) => {
        resolve(result);
      };

      const onFailure = (error: errorType) => {
        const { errorCode, errorText } = error;
        logger(`getNetwork Error Code ${errorCode} message ${errorText}`);
        resolve(null);
      };

      try {
        this.deviceInfo.getNetworkInfo(onSuccess, onFailure);
      } catch (e) {
        console.error('deviceInfo.getNetworkInfo error', e);
      }
    });
  }

  getMacAddress(): Promise<getNetworkMacInfoType | null> {
    return new promise((resolve, reject) => {
      const onSuccess = (result: getNetworkMacInfoType) => {
        resolve(result);
      };

      const onFailure = (error: errorType) => {
        const { errorCode, errorText } = error;
        logger(`getNetworkMacInfo Error Code ${errorCode} message ${errorText}`);
        resolve(null);
      };

      try {
        this.deviceInfo.getNetworkMacInfo(onSuccess, onFailure);
      } catch (e) {
        console.error('deviceInfo.getNetworkMacInfo error', e);
      }
    });
  }

  getNetwork(): Promise<GetNetworkType> {
    return this.getIpAddress().then((ip) =>
      this.getMacAddress().then((mac) => {
        logger('LG getNetwork:', { ip, mac });
        return {
          lan: {
            ip: ip?.wired?.ipAddress,
            mac: mac?.wiredInfo?.macAddress,
          },
          wifi: {
            ip: ip?.wifi?.ipAddress,
            mac: mac?.wifiInfo?.macAddress,
          },
          vpn: {
            ip: null,
          },
        };
      }),
    );
  }

  private findTimezone(timezone: string): Promise<ITimezone | null> {
    console.log('[findTimezone]', { timezone });
    return new Promise((resolve) => {
      const onSuccess = (cbObject: { timeZone: ITimezone[] }) => {
        console.log('[findTimezone] onSuccess', { cbObject });
        // eslint-disable-next-line no-cond-assign
        for (let i = 0, timeZone; (timeZone = cbObject.timeZone[i]); i += 1) {
          if (timezone.includes(timeZone.city)) {
            resolve(timeZone);
          }
        }
      };

      const onFailure = (cbObject: errorType) => {
        console.log('[findTimezone] onFailure', { cbObject });
        this.failureCb(cbObject);
        resolve(null);
      };

      this.configuration.getTimeZoneList(onSuccess, onFailure);
    });
  }

  setDeviceTimezone(timezone: ITimezone): Promise<boolean> {
    console.log('[setDeviceTimezone]', { timezone });
    return new Promise<boolean>((resolve) => {
      const { continent, country, city } = timezone;
      const options = {
        timeZone: {
          continent,
          country,
          city,
        },
      };
      const onSuccess = () => {
        console.log('[setDeviceTimezone] onSuccess', timezone);
        resolve(true);
      };

      const onFailure = (error: errorType) => {
        console.log('[setDeviceTimezone] onFailure', timezone);
        this.failureCb(error);
        resolve(false);
      };

      console.log('[setDeviceTimezone] options', options);
      this.configuration.setTimeZone(onSuccess, onFailure, options);
    });
  }

  async setTimezone(timezone: string): Promise<boolean> {
    console.log('[setTimezone]', { timezone });
    const actualTimezone = await this.findTimezone(timezone);
    console.log('[setTimezone] actualTimezone', { actualTimezone });
    if (!actualTimezone) {
      return false;
    }

    return this.setDeviceTimezone(actualTimezone);
  }
}

function getWebOsVersion(): Promise<string> {
  return new promise((resolve, reject) => {
    // @ts-ignore
    const custom = new Custom();

    const onSuccess = (result: { webOSVersion: string }) => {
      logger('webOSVersion:', result.webOSVersion);
      resolve(result.webOSVersion);
    };

    const onFailure = (error: errorType) => {
      const { errorCode, errorText } = error;
      logger(`getNetworkMacInfo Error Code ${errorCode} message ${errorText}`);
      resolve('');
    };

    custom.Signage.getwebOSVersion(onSuccess, onFailure);
  });
}

const WEBOS_VERSION = {
  '1.0': '1.2',
  '2.0': '1.3',
  '3.0': '1.4',
  '3.2': '1.5',
  '4.0': '1.6',
  '4.1': '1.7',
  '6.0': '1.8',
};

function getScapVersion(): Promise<string | null> {
  return new promise((resolve) => {
    getWebOsVersion().then((version) => {
      // eslint-disable-next-line no-prototype-builtins
      if (WEBOS_VERSION.hasOwnProperty(version)) {
        // @ts-ignore
        const webosVersion = WEBOS_VERSION[version];
        resolve(webosVersion);
      } else {
        resolve('1.7');
      }
    });
  });
}

function importJS(link: string) {
  return new promise((resolve, reject) => {
    const headElement = document.getElementsByTagName('head')[0];
    const scriptElement = document.createElement('script');

    scriptElement.type = 'text/javascript';
    scriptElement.src = link;
    scriptElement.onload = () => {
      console.log('script load');
      resolve(link);
    };
    scriptElement.onerror = () => {
      console.log('failed load script');
      resolve('failed load script');
    };

    headElement.appendChild(scriptElement);
  });
}

function importLibs(scapVersion: string) {
  return promise.all([
    importJS(`js/cordova-cd/${scapVersion}/signage.js`),
    importJS(`js/cordova-cd/${scapVersion}/storage.js`),
    importJS(`js/cordova-cd/${scapVersion}/configuration.js`),
    importJS(`js/cordova-cd/${scapVersion}/deviceInfo.js`),
    importJS(`js/cordova-cd/${scapVersion}/power.js`),
    importJS(`js/cordova-cd/${scapVersion}/sound.js`),
    importJS(`js/cordova-cd/${scapVersion}/video.js`),
    // importJS(`js/cordova-cd/${scapVersion}/time.js`),
    // importJS(`js/cordova-cd/${scapVersion}/utility.js`),
    // importJS(`js/cordova-cd/${scapVersion}/security.js`),
  ]);
}

export function initApi() {
  return getScapVersion()
    .then((scapVersion) => {
      if (!scapVersion) {
        throw new Error('Failed to detect scap version');
      }
      console.log('scapVersion', scapVersion);
      return importLibs(scapVersion);
    })
    .then(() => {
      logger('imports finished');
    })
    .catch((error) => console.error(error));
}

export default Web0sDevice;
