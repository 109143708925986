import React, { createContext } from 'react';
import type { Socket } from 'socket.io-client';
import * as Types from 'src/types/main';
import Device from 'src/class/control-device';

export const WebsocketContext: React.Context<Socket | null> = createContext<Socket | null>(null);
export const DeviceContext: React.Context<Device | null> = createContext<Device | null>(null);
export const SettingsContext: React.Context<Types.SettingsType | null> = createContext<Types.SettingsType | null>(
  null,
);

export const RawScheduleContext: React.Context<Types.DataType | null> = createContext<Types.DataType | null>(
  null,
);
export const ScheduleContext: React.Context<Types.ScheduleType | null> = createContext<Types.ScheduleType | null>(
  null,
);
export const ActiveTimeslotContext: React.Context<Types.TimeslotType | null> = createContext<Types.TimeslotType | null>(
  null,
);
export const TemplateContext: React.Context<Types.TemplateType | null> = createContext<Types.TemplateType | null>(
  null,
);
