import styled from 'styled-components';

export const Container = styled.ul`
  list-style: none;
  width: 100%;
  padding: 0;
`;

export const Item = styled.li<{ separatorColor: string; separatorSize: number }>`
  display: flex;
  padding: 1.25rem;
  align-items: center;

  & > div > img {
    max-height: 8rem;
    width: 8rem;

    text-align: center;
    line-height: 8rem;
    object-fit: contain;

    &.event-logo {
      background-color: #313a46;
      color: #f1f3fa;
      font-weight: normal;
      font-size: 2rem;
      font-family: 'Lato';
    }
  }

  & > .wayfinding-icon {
    margin-left: auto;
    justify-self: center;

    background-color: transparent;

    height: 6.25rem;
    width: 6.25rem;
    line-height: 8rem;
  }
  .wayfinding-location-name {
    font-size: 0.7em;

    & > hr {
      width: 0.5rem;
      margin: 0 0.625rem;
    }

    & > h3 {
      margin-left: 0.75rem;
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
    }

    & > .event-time {
      display: flex;
      padding: 0;
      margin: 0;
    }
  }

  & + hr {
    width: 80%;
    height: ${({ separatorSize }) => separatorSize - 1}px;
    border-color: ${({ separatorColor }) => separatorColor};
    background-color: ${({ separatorColor }) => separatorColor};
  }
`;

export const ImgContainer = styled.div`
  min-width: 8rem;
`;

export const LocationName = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 1.25rem;
`;

export const Title = styled.span`
  color: ${({ color }) => color};
`;

export const Time = styled.span`
  color: ${({ color }) => color};
`;

export const Location = styled.span`
  color: ${({ color }) => color};
`;
