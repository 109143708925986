import time from 'src/utils/time';

export default function getTimezone() {
  let timezone = 'UTC';

  try {
    timezone = time.tz.guess();
  } catch (e) {
    console.error('[APP][getTimezone] Error: ', e);
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  return timezone;
}
