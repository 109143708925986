import 'intl';
import 'intl/locale-data/jsonp/en';
import '@formatjs/intl-locale/polyfill';
import 'date-time-format-timezone';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'custom-event-polyfill';
import { Promise as promise } from 'bluebird';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Offline as OfflineIntegration } from '@sentry/integrations';
import type { Integration } from '@sentry/types';
// @ts-ignore
import { NavigationProvider } from 'react-keyboard-navigation';
import isEqual from 'lodash/isEqual';
import Screen from 'src/utils/screen';
import time from 'src/utils/time';
import logger from 'src/utils/logger';
import getTimezone from 'src/utils/get-timezone';
import { getContent } from 'src/utils/schedule-play';
import {
  getSettingsFromLocalDB,
  updateDefaultMediaInLocalDB,
  updateEverydayScheduleInLocalDB,
  updateMediaFilesInLocalDB,
  updateSettingsInLocalDB,
} from 'src/utils/local-db';
//
import TizenDevice from 'src/tizen/device';
import Web0sDevice, { initApi } from 'src/webos/device';
import RaspberryDevice from 'src/raspberry/device';
import ChromiumDevice from 'src/chrome/device';
import Device from 'src/class/control-device';
import screenshot from 'src/components/screenshot';
import * as Types from 'src/types/main';
import type { Socket as Websocket } from 'socket.io-client';
//
import io from 'socket.io-client';
import useInterval from './hooks/use-interval';
import {
  ActiveTimeslotContext,
  DeviceContext,
  RawScheduleContext,
  SettingsContext,
  WebsocketContext,
} from './context';
import useFetch, { FetchOptions } from './hooks/use-fetch';
// Components
import Registration from './routes/registration';
import App from './routes/app';
import DeviceNameContainer from './components/device-name';
import DisplayText from './components/display-text';
import DebuggerComponent from './components/debugger';
// Styles
import './style/main.css';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENV,
  // None = 0, // No logs will be generated
  // Error = 1, // Only SDK internal errors will be logged
  // Debug = 2, // Information useful for debugging the SDK will be logged
  // Verbose = 3 // All SDK actions will be logged
  integrations: [
    new OfflineIntegration({
      // limit how many events will be locally saved. Defaults to 30.
      maxStoredEvents: 30,
    }) as typeof OfflineIntegration extends infer T ? (T extends Integration ? T : never) : never,
  ],
  logLevel: process.env.RUNTIME_ENV === 'production' ? 2 : 3,
});
window.packageVersion = '1.2.281';
window.needReload = false;
//
const timezoneDevice = getTimezone();
window.timezoneDevice = timezoneDevice;
//
window.hostname = process.env.API_HOST || 'localhost';

let disconnectionWatcherTimeout: number | null = null;

// Init block
const screen = new Screen();

let customWidth = null;
let customHeight = null;

if (typeof URLSearchParams === 'function') {
  const params = new URLSearchParams(window.location.href);
  customWidth = params.get('width');
  customHeight = params.get('height');
}
window.screenHeight = customHeight || window.screen.height;
window.screenWidth = customWidth || window.screen.width;
const { screenHeight, screenWidth } = window;
window.ratio = screen.calculateRation(screenWidth, screenHeight);
logger('ratio', { screenHeight, screenWidth });
//
// Backward compatibility for LocalStorage from Immortal-DB
const tokenImmortal = window.localStorage.getItem('_immortal|token');
const tokenLocalStorage = window.localStorage.getItem('token');
if (!tokenLocalStorage && tokenImmortal) {
  window.localStorage.setItem('token', tokenImmortal);
  window.localStorage.removeItem('_immortal|token');
}

//
// Create the 'root' entry point into the app.  If we have React hot loading
// (i.e. if we're in development), then we'll wrap the whole thing in an
// <AppContainer>.  Otherwise, we'll jump straight to the browser router
async function doRender() {
  // @ts-ignore
  ReactDOM[module.hot ? 'render' : 'hydrate'](
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag('packageVersion', window.packageVersion);
      }}
      fallback={
        (/* { error, componentStack, resetError } */) => (
          <>
            <DisplayText center>You have encountered a fatal error, please reload your device</DisplayText>
          </>
        )
      }
    >
      <Root />
    </Sentry.ErrorBoundary>,
    //
    document.getElementById('root'),
  );
}
// The <Root> component.  We'll run this as a self-contained function since
// we're using a bunch of temporary vars that we can safely discard.

// If we have hot reloading enabled (i.e. if we're in development), then
// we'll wrap the whole thing in <AppContainer> so that our views can respond
// to code changes as needed
const Root = (() => () => {
  const [token, setToken] = useState<string | null>(null);
  const fetchOptions = useMemo<FetchOptions>(
    () => ({
      headers: {
        'Content-Type': 'application/json',
        Authorization: token || '',
      },
      method: 'GET',
      skip: !token,
      responseType: 'json',
    }),
    [token],
  );
  const url = useMemo(() => new URL(`${process.env.REST_URL}?weekNumber=${time().isoWeek()}`), []);
  const { status, data, error, refetch } = useFetch<Types.DataType>(url.href, fetchOptions);

  const [websocketConnectionStatus, setSocketConnectionStatus] = useState<boolean>(false);
  const [websocketConnection, setSocketConnection] = useState<Websocket | null>(null);
  const [settings, setSettings] = useState<Types.SettingsType | null>(null);
  const [isOffline, setIsOffline] = useState(false);
  const { locationId = null } = settings || {};
  const [isStarting, setStarting] = useState<boolean>(false);
  const [activeTimeslot, setActiveTimeslot] = useState<Types.TimeslotType | null>(null);

  const deviceType = Device.getType();
  //
  const deviceProps = useMemo(
    () => ({
      deviceType,
      startSchedule: () => {
        setStarting(true);
        logger('StartSchedule call');
      },
      packageVersion: window.packageVersion,
    }),
    [deviceType],
  );

  const device = useMemo(() => {
    switch (deviceType) {
      case 'tizen':
        return new TizenDevice(deviceProps);
      case 'web0s':
        return new Web0sDevice(deviceProps);
      case 'arm':
        return new RaspberryDevice(deviceProps);
      case 'chrome':
        return new ChromiumDevice(deviceProps);
      default:
        throw new Error('Not find device in userAgent');
    }
  }, []) as Device;
  const deviceId = device?.id || '';

  useEffect(() => {
    if (!token || !websocketConnection || !websocketConnectionStatus) {
      return;
    }
    (async () => {
      logger('Emit device-info', {
        timezone: timezoneDevice,
        appVersion: window.packageVersion,
        network: await device.getNetwork(),
        metadata: await device.getVersion(),
      });

      websocketConnection.emit('device-info', {
        timezone: timezoneDevice,
        appVersion: window.packageVersion,
        network: await device.getNetwork(),
        metadata: await device.getVersion(),
      });

      if (window.waitConnect) {
        // await handleSchedule(); //!TODO
        window.waitConnect = false;
      }
    })();
  }, [token, websocketConnection, websocketConnectionStatus]);

  const reconnectWSConnection = useCallback(() => {
    logger('reconnectWSConnection.start', { websocketConnection, token, device });
    websocketConnection?.disconnect();
    logger('reconnectWSConnection.disconnect');
    // socket.io connection
    const authToken = token || '';
    const socket = io(`https://${process.env.API_HOST}`, {
      reconnection: true,
      auth: {
        token,
        deviceId,
      },
      extraHeaders: {
        'x-device-id': deviceId,
        'x-auth-token': authToken,
      },
      transports: ['websocket', 'polling'], // use WebSocket first, if available
    });
    logger('reconnectWSConnection.newSocket', socket);
    setSocketConnection(socket);

    socket.on('connect', async () => {
      logger('Websocket is connected', { socket: socket.connected, isOffline });
      setSocketConnectionStatus(true);

      if (disconnectionWatcherTimeout) {
        window.clearTimeout(disconnectionWatcherTimeout);
        disconnectionWatcherTimeout = null;
      }

      if (isOffline) {
        logger('Online mode ...');
        refetch(fetchOptions);
        setIsOffline(false);
      }
    });

    socket.on('disconnect', () => {
      logger('Websocket disconnected');
      setSocketConnectionStatus(false);

      if (!disconnectionWatcherTimeout) {
        disconnectionWatcherTimeout = window.setTimeout(() => {
          logger('isOffline = true');
          setIsOffline(true);
        }, 30000);
      }
    });

    logger('Subscribe to websocket events');

    socket.on('error', (err: Error) => {
      console.error('error', err);
    });

    socket.on('connect_error', (err: Error) => {
      console.error('connect_error', err);
    });

    socket.on('connect_failed', (err: Error) => {
      console.error('connect_failed', err);
      // Revert to classic upgrade
      socket.io.opts.transports = ['polling', 'websocket'];
    });

    socket.on('device-registered', (msg: { token: string; name: string }) => {
      setSettings(updateSettingsInLocalDB({ deviceName: msg.name }));
      window.localStorage.setItem('token', msg.token);
      setToken(msg.token);
    });

    socket.on('device-reload', () => {
      device.reload();
    });

    socket.on('device-take-screenshot', async () => {
      logger('call device-take-screenshot');
      const key = window.localStorage.getItem('token') || '';
      await screenshot(device, key);
    });

    socket.on('device-reboot', () => {
      device.reboot();
    });

    socket.on('device-removed', () => {
      clearData();
    });

    socket.on('update-schedule', () => {
      logger('Updating schedule...');
      refetch({ ...fetchOptions, headers: { ...fetchOptions.headers, Authorization: token || '' } });
      setActiveTimeslot(null);
      setStarting(false);
    });

    // Block for Raspberry
    // Start
    if (device instanceof RaspberryDevice) {
      socket.on('overscan-settings-update', (msg: Types.SetOverscanType) => {
        device.setOverscan(msg);
      });

      socket.on('wifi-settings-update', (msg: Types.WifiConfigType) => {
        device.wifiConfig(msg);
      });
    }
    // End

    socket.on('device-update-firmware', (msg: { version: string }) => {
      device.updateFirmware(msg.version);
    });

    socket.on('device-update', async (deviceInfo: Types.DeviceInfoType) => {
      if (deviceInfo) {
        logger('device-update', { deviceInfo, device });
        const { name, locationId: placeId } = deviceInfo;
        const { orientation, debug, invertVideoRotation, resolution } = deviceInfo.props || {};

        if (typeof orientation === 'number' && settings?.orientation !== orientation) {
          screen.rotate(orientation);
        }

        if (
          typeof resolution !== 'undefined' &&
          !isEqual(settings?.resolution, resolution) &&
          device instanceof RaspberryDevice
        ) {
          await device.changeResolution(resolution);
        }

        setSettings(
          updateSettingsInLocalDB({
            orientation: typeof orientation !== 'undefined' ? orientation : settings?.orientation,
            debug: typeof debug !== 'undefined' ? debug : false,
            deviceName: typeof name !== 'undefined' ? name : settings?.deviceName,
            resolution: typeof resolution !== 'undefined' ? resolution : settings?.resolution,
            locationId: placeId,
            invertVideoRotation:
              typeof invertVideoRotation !== 'undefined'
                ? invertVideoRotation
                : settings?.invertVideoRotation,
          }),
        );
      }
    });
  }, [
    setSocketConnection,
    setSocketConnectionStatus,
    websocketConnection,
    token,
    deviceId,
    isOffline,
    fetchOptions,
  ]);

  const clearData = useCallback(() => {
    logger('Run function clearData');

    updateEverydayScheduleInLocalDB(null);
    updateDefaultMediaInLocalDB(null);
    updateMediaFilesInLocalDB([]);
    updateSettingsInLocalDB(null);

    window.localStorage.removeItem('token');
    setToken(null);
    store?.purge(); //! TIZEN
    logger('stop video clearData');
    player?.stopVideo();
    setStarting(false);
    setActiveTimeslot(null);

    if (window.idStoreInterval) {
      window.clearInterval(window.idStoreInterval);
      window.idStoreInterval = null;
    }
  }, [setSocketConnection, setActiveTimeslot, setStarting]);

  useEffect(() => {
    if (status !== 'error') {
      return;
    }
    const usedToken = error?.headers?.Authorization;
    const usedCurrentTokenButItIsInvalid = error?.status === 403 && usedToken && usedToken === token;
    if (usedCurrentTokenButItIsInvalid) {
      logger('Response 403!!!');
      Sentry.captureException(error);
      Sentry.withScope((scope) => {
        scope.setTag('packageVersion', window.packageVersion);
        scope.setTag('token', token);
        scope.setTag('RegCode', window.localStorage.getItem('code'));
        scope.setTag('DeviceID', window.localStorage.getItem('deviceId'));
        scope.setLevel(Sentry.Severity.Info);
        Sentry.captureException('info');
      });
      clearData();
    }
    if (!error?.status) {
      logger('fetch status error');
      device.store.progress = 100;
      device?.startSchedule();
      setIsOffline(true);
    }
  }, [status, clearData, token, error]);

  useLayoutEffect(() => {
    setToken(window.localStorage.getItem('token'));
  }, [setToken]);

  useEffect(() => {
    console.log('Auth token has been changed', { token });
    reconnectWSConnection();
  }, [token]);

  useEffect(() => {
    setSettings(getSettingsFromLocalDB());
  }, [setSettings]);

  useInterval(
    () => {
      if (!isStarting) {
        return;
      }

      getContent({ locationId })
        .then((timeslot) => {
          if (timeslot?.id === activeTimeslot?.id) {
            return;
          }
          setActiveTimeslot(timeslot);
        })
        .catch(logger);
    },
    isStarting ? 1000 : null,
  );

  //* Send network data to server every 10 minutes
  useInterval(() => {
    if (!device || !websocketConnection) {
      return;
    }

    device
      .getNetwork()
      .then((network) => {
        const { network: networkFromDB } = getSettingsFromLocalDB();
        if (JSON.stringify(networkFromDB) === JSON.stringify(network)) {
          logger('return');
          return;
        }
        logger('update network', { network, networkFromDB });
        updateSettingsInLocalDB({ network });
        websocketConnection.emit('device-info', { network });
      })
      .catch((err) => {
        logger('getNetwork error', err);
      });
  }, 10 * 60000); // every 10 minutes send network data

  //* this useLayoutEffect need for initial set media item when application first start
  useLayoutEffect(() => {
    if (!isStarting) {
      return;
    }

    getContent({ locationId }).then(setActiveTimeslot).catch(logger);
  }, [isStarting, locationId]);

  const { store, player } = device;
  screen.rotate(settings?.orientation || 0);
  return (
    <>
      <WebsocketContext.Provider value={websocketConnection}>
        <SettingsContext.Provider value={settings}>
          <DeviceContext.Provider value={device}>
            <RawScheduleContext.Provider value={data || null}>
              <ActiveTimeslotContext.Provider value={activeTimeslot}>
                <NavigationProvider>
                  <>
                    {!token && <Registration websocketConnectionStatus={websocketConnectionStatus} />}
                    {!!token && (
                      <>
                        <App
                          token={token}
                          isStarting={isStarting}
                          websocketConnectionStatus={websocketConnectionStatus}
                        />
                        <DeviceNameContainer />
                        <DebuggerComponent />
                      </>
                    )}
                  </>
                </NavigationProvider>
              </ActiveTimeslotContext.Provider>
            </RawScheduleContext.Provider>
          </DeviceContext.Provider>
        </SettingsContext.Provider>
      </WebsocketContext.Provider>
    </>
  );
})();

function preparation() {
  return new promise((resolve, reject) => {
    logger('call preparation');
    const deviceType = Device.getType();
    switch (deviceType) {
      case 'web0s':
        initApi()
          .then(() => resolve(undefined))
          .catch((e) => logger('error initApi', e));
        break;
      case 'tizen':
        resolve('init');
        break;
      case 'arm':
        resolve('init');
        break;
      case 'chrome':
        resolve('init');
        break;
      default:
        reject('Not find device in userAgent');
    }
  });
}

preparation()
  .then(() => doRender())
  .catch((e) => logger('preparation error', e));
